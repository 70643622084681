import {
  Input,
  Component,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'snr-single-image-panel',
  templateUrl: './single-image-panel.component.html',
  styleUrls: ['./single-image-panel.component.scss']
})
export class SingleImagePanelComponent implements OnChanges {
  @Input()
  imageUrl: string;
  @Output() imageUrlChange: EventEmitter<string> = new EventEmitter<string>();

  //duplicatedImageUrl is the one that is output in the UI. When it's not equal to imageUrl, then it means imageUrl was marked as deleted.
  duplicatedImageUrl: string;

  fileDragInProgress: boolean = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.imageUrl) {
      this.duplicatedImageUrl = changes.imageUrl.currentValue;
    }
  }

  onDragOverFile(event): void {
    this.fileDragInProgress = true;
    event.stopPropagation();
    event.preventDefault();
  }
  onDragLeaveFile($event): void {
    this.fileDragInProgress = false;
    event.stopPropagation();
    event.preventDefault();
  }

  onDropFile(event: DragEvent) {
    this.fileDragInProgress = false;
    event.preventDefault();
    this._addImageUrl(event.dataTransfer.files);
  }

  onFileChanged(event) {
    this._addImageUrl(event.target.files);
  }

  onMarkImageUrlAsDeleted(imageUrl) {
    this.imageUrl = '';
    this.imageUrlChange.emit(this.imageUrl);

    if (imageUrl.indexOf('data') === 0) {
      //A dataUri; which means it's a newly added image. We will really be removing it...
      this.duplicatedImageUrl = '';
    }
  }

  onUnmarkImageUrlAsDeleted(imageUrl) {
    this.imageUrl = imageUrl;
    this.imageUrlChange.emit(this.imageUrl);
  }

  _addImageUrl(files: FileList) {
    if (files.length === 0) {
      //console.log('No file selected!');
      return;
    }

    let file: File = files[0];
    const fileReader = new FileReader();
    fileReader.addEventListener(
      'load',
      () => {
        const dataUriImageUrl = <string>fileReader.result;
        this.imageUrl = dataUriImageUrl;
        this.imageUrlChange.emit(this.imageUrl);
        this.duplicatedImageUrl = dataUriImageUrl;
      },
      false
    );
    fileReader.readAsDataURL(file);
  }
}
