import { Input, Component, Output, EventEmitter } from '@angular/core';
import { ToasterService } from '../../../../../node_modules/angular2-toaster';
import { WorkshopRegistrationBackupApi } from '../../../shared/sdk';
import { of as observableOf } from 'rxjs';
import { ConfirmModalService } from './confirmmodal';

import { PreviewMailModalService } from '../preview-mail-modal/preview-mail-modal.service';
import { MailPreview } from '../preview-mail-modal/mail-preview.interface';

@Component({
  selector: 'snr-workshop-registration-backup-send-registration-link-button',
  templateUrl: './workshop-registration-backup-send-registration-link-button.component.html',
  styleUrls: ['./buttons.scss']
})
export class WorkshopRegistrationBackupSendRegistrationLinkButtonComponent {
  @Input()
  workshopRegistrationBackup: any;
  @Output()
  sent: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private confirmModalService: ConfirmModalService,
    //tslint:disable-next-line
    private toasterService: ToasterService,
    //tslint:disable-next-line
    private workshopRegistrationBackupService: WorkshopRegistrationBackupApi,
    //tslint:disable-next-line
    private previewMailModalService: PreviewMailModalService
  ) {}

  onSendRegistrationLink(): void {
    const { header, content, options } = getHeaderContentAndOptions.call(this);
    this.confirmModalService.confirm(header, content, options, () => {
      return sendInfo.call(this);
    });

    function getHeaderContentAndOptions() {
      return {
        header: 'Registratie link mailen?',
        content: `
          <p>Weet je zeker dat je deze persoon een link wilt sturen waarmee ze zich kan inschrijven?</p>
          <p>Wat zal er gebeuren?</p>
          <ul>
            <li>De persoon krijgt een mail toegestuurd met daarin een link waarmee ze zich alsnog kan inschrijven voor de cursus, ondanks het feit dat de cursus als volzet is aangegeven op de website</li>
          </ul>`,
        options: [
          {
            name: 'checkEmails',
            label: 'Bekijk de mail die verstuurd zal worden',
            inputType: 'button',
            click: () => {
              this.workshopRegistrationBackupService
                .getMailForSendRegistrationLink(this.workshopRegistrationBackup.id)
                .subscribe(mail => {
                  const mailPreviews: MailPreview[] = [
                    {
                      to: mail.to,
                      html: mail.body
                    }
                  ];
                  this.previewMailModalService.showMailPreviews(mailPreviews);
                });
            }
          }
        ]
      };
    }

    function sendInfo() {
      return this.workshopRegistrationBackupService
        .sendRegistrationLink(this.workshopRegistrationBackup.id)
        .flatMap(() => {
          this.sent.emit();
          this.toasterService.pop(
            'success',
            'Mail is verstuurd',
            'De mail met de inschrijvingslink is onderweg naar de persoon...'
          );
          return observableOf(null);
        });
    }
  }
}
