import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbPopoverModule } from '@nebular/theme';
import { ConfirmModalService } from './confirmmodal';

import { ConfirmWorkshopRegistrationButtonComponent } from './confirm-workshop-registration-button.component';
import { CancelWorkshopRegistrationButtonComponent } from './cancel-workshop-registration-button.component';

import { WorkshopRegistrationPriceService } from '../../../services/workshop/workshopregistrationprice';
import { WorkshopRegistrationPaymentModalService } from '../../../services/workshop/workshopregistrationpaymentmodal';
import { WorkshopRegistrationInvoiceModalService } from '../../../services/workshop/workshopregistrationinvoicemodal';

import { WorkshopRegistrationSendPaymentInformationButtonComponent } from './workshop-registration-send-payment-information-button.component';
import { WorkshopRegistrationMarkPaymentButtonComponent } from './workshop-registration-mark-payment-button.component';
import { WorkshopRegistrationDetermineRefundAmountButtonComponent } from './workshop-registration-determine-refund-amount-button.component';
import { FollowUpOnProvingValidityWorkshopRegistrationButtonComponent } from './follow-up-on-proving-validity-workshop-registration-button.component';
import { WorkshopRegistrationMarkRefundButtonComponent } from './workshop-registration-mark-refund-button.component';

import { WorkshopRegistrationInvoiceSentButtonComponent } from './workshop-registration-invoice-sent-button.component';
import { WorkshopRegistrationSendInfoButtonComponent } from './workshop-registration-send-info-button.component';
import { WorkshopRegistrationSendLastReminderButtonComponent } from './workshop-registration-send-last-reminder-button.component';

import { WorkshopRegistrationBackupCheckForInterestButtonComponent } from './workshop-registration-backup-check-for-interest-button.component';
import { WorkshopRegistrationBackupSendRegistrationLinkButtonComponent } from './workshop-registration-backup-send-registration-link-button.component';

import { OrderPaymentModalService } from '../../../services/order/orderpaymentmodal';
import { OrderInvoiceModalService } from '../../../services/order/orderinvoicemodal';
import { OrderMarkPaymentButtonComponent } from './order-mark-payment-button.component';
import { OrderInvoiceSentButtonComponent } from './order-invoice-sent-button.component';
import { OrderMarkAsAssembledButtonComponent } from './order-mark-as-assembled-button.component';
import { OrderMarkAsHandledButtonComponent } from './order-mark-as-handled-button.component';

import { ELearningClassEnrollmentMarkAsAssembledButtonComponent } from './elearning-class-enrollment-mark-as-assembled-button.component';
import { ELearningClassEnrollmentMarkAsHandledButtonComponent } from './elearning-class-enrollment-mark-as-handled-button.component';
import { ELearningClassEnrollmentInvoiceSentButtonComponent } from './elearning-class-enrollment-invoice-sent-button.component';
import { ELearningClassEnrollmentInvoiceModalService } from '../../../services/elearningclassenrollment/elearningclassenrollmentinvoicemodal';

@NgModule({
  imports: [CommonModule, NbPopoverModule],
  declarations: [
    ConfirmWorkshopRegistrationButtonComponent,
    CancelWorkshopRegistrationButtonComponent,
    WorkshopRegistrationSendPaymentInformationButtonComponent,
    WorkshopRegistrationMarkPaymentButtonComponent,
    WorkshopRegistrationDetermineRefundAmountButtonComponent,
    FollowUpOnProvingValidityWorkshopRegistrationButtonComponent,
    WorkshopRegistrationMarkRefundButtonComponent,
    WorkshopRegistrationSendInfoButtonComponent,
    WorkshopRegistrationInvoiceSentButtonComponent,
    WorkshopRegistrationSendLastReminderButtonComponent,
    WorkshopRegistrationBackupCheckForInterestButtonComponent,
    WorkshopRegistrationBackupSendRegistrationLinkButtonComponent,
    OrderMarkPaymentButtonComponent,
    OrderInvoiceSentButtonComponent,
    OrderMarkAsAssembledButtonComponent,
    OrderMarkAsHandledButtonComponent,
    ELearningClassEnrollmentMarkAsAssembledButtonComponent,
    ELearningClassEnrollmentMarkAsHandledButtonComponent,
    ELearningClassEnrollmentInvoiceSentButtonComponent,
  ],
  providers: [
    ConfirmModalService,
    WorkshopRegistrationPriceService,
    WorkshopRegistrationPaymentModalService,
    WorkshopRegistrationInvoiceModalService,
    OrderPaymentModalService,
    OrderInvoiceModalService,
    ELearningClassEnrollmentInvoiceModalService,
  ],
  entryComponents: [],
  exports: [
    ConfirmWorkshopRegistrationButtonComponent,
    CancelWorkshopRegistrationButtonComponent,
    WorkshopRegistrationSendPaymentInformationButtonComponent,
    WorkshopRegistrationMarkPaymentButtonComponent,
    WorkshopRegistrationDetermineRefundAmountButtonComponent,
    FollowUpOnProvingValidityWorkshopRegistrationButtonComponent,
    WorkshopRegistrationMarkRefundButtonComponent,
    WorkshopRegistrationSendInfoButtonComponent,
    WorkshopRegistrationInvoiceSentButtonComponent,
    WorkshopRegistrationSendLastReminderButtonComponent,
    WorkshopRegistrationBackupCheckForInterestButtonComponent,
    WorkshopRegistrationBackupSendRegistrationLinkButtonComponent,
    OrderMarkPaymentButtonComponent,
    OrderInvoiceSentButtonComponent,
    OrderMarkAsAssembledButtonComponent,
    OrderMarkAsHandledButtonComponent,
    ELearningClassEnrollmentMarkAsAssembledButtonComponent,
    ELearningClassEnrollmentMarkAsHandledButtonComponent,
    ELearningClassEnrollmentInvoiceSentButtonComponent,
  ]
})
export class ButtonsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ButtonsModule,
      providers: []
    };
  }
}
