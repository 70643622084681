/* tslint:disable */
import {
  WorkshopRegistration,
  Log
} from '../index';

declare var Object: any;
export interface WorkshopInterface {
  "relatedWorkshopSubjectId": any;
  "status": string;
  "price"?: number;
  "availablePlaces"?: number;
  "registrationUrl"?: string;
  "dates": Array<any>;
  "address": any;
  "private": boolean;
  "providedExtras"?: any;
  "id"?: any;
  Workshopregistrations?: WorkshopRegistration[];
  Logs?: Log[];
}

export class Workshop implements WorkshopInterface {
  "relatedWorkshopSubjectId": any;
  "status": string;
  "price": number;
  "availablePlaces": number;
  "registrationUrl": string;
  "dates": Array<any>;
  "address": any;
  "private": boolean;
  "providedExtras": any;
  "id": any;
  Workshopregistrations: WorkshopRegistration[];
  Logs: Log[];
  constructor(data?: WorkshopInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Workshop`.
   */
  public static getModelName() {
    return "Workshop";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Workshop for dynamic purposes.
  **/
  public static factory(data: WorkshopInterface): Workshop{
    return new Workshop(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Workshop',
      plural: 'Workshops',
      path: 'Workshops',
      idName: 'id',
      properties: {
        "relatedWorkshopSubjectId": {
          name: 'relatedWorkshopSubjectId',
          type: 'any'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "price": {
          name: 'price',
          type: 'number'
        },
        "availablePlaces": {
          name: 'availablePlaces',
          type: 'number'
        },
        "registrationUrl": {
          name: 'registrationUrl',
          type: 'string'
        },
        "dates": {
          name: 'dates',
          type: 'Array&lt;any&gt;'
        },
        "address": {
          name: 'address',
          type: 'any'
        },
        "private": {
          name: 'private',
          type: 'boolean'
        },
        "providedExtras": {
          name: 'providedExtras',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        Workshopregistrations: {
          name: 'Workshopregistrations',
          type: 'WorkshopRegistration[]',
          model: 'WorkshopRegistration',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'relatedWorkshopId'
        },
        Logs: {
          name: 'Logs',
          type: 'Log[]',
          model: 'Log',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'relatedWorkshopId'
        },
      }
    }
  }
}
