/* tslint:disable */

declare var Object: any;
export interface VATInterface {
  "vat": string;
  "country": string;
  "name": string;
  "streetAndNr": string;
  "postalCodeAndCity": string;
  "id"?: number;
}

export class VAT implements VATInterface {
  "vat": string;
  "country": string;
  "name": string;
  "streetAndNr": string;
  "postalCodeAndCity": string;
  "id": number;
  constructor(data?: VATInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `VAT`.
   */
  public static getModelName() {
    return "VAT";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of VAT for dynamic purposes.
  **/
  public static factory(data: VATInterface): VAT{
    return new VAT(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'VAT',
      plural: 'VAT',
      path: 'VAT',
      idName: 'id',
      properties: {
        "vat": {
          name: 'vat',
          type: 'string'
        },
        "country": {
          name: 'country',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "streetAndNr": {
          name: 'streetAndNr',
          type: 'string'
        },
        "postalCodeAndCity": {
          name: 'postalCodeAndCity',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
