/* tslint:disable */

declare var Object: any;
export interface StoryInterface {
  "nl": any;
  "en": any;
  "chapterSequenceNumber": number;
  "storySequenceNumber": number;
  "imageUrls"?: Array<any>;
  "id"?: any;
}

export class Story implements StoryInterface {
  "nl": any;
  "en": any;
  "chapterSequenceNumber": number;
  "storySequenceNumber": number;
  "imageUrls": Array<any>;
  "id": any;
  constructor(data?: StoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Story`.
   */
  public static getModelName() {
    return "Story";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Story for dynamic purposes.
  **/
  public static factory(data: StoryInterface): Story{
    return new Story(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Story',
      plural: 'Stories',
      path: 'Stories',
      idName: 'id',
      properties: {
        "nl": {
          name: 'nl',
          type: 'any'
        },
        "en": {
          name: 'en',
          type: 'any'
        },
        "chapterSequenceNumber": {
          name: 'chapterSequenceNumber',
          type: 'number'
        },
        "storySequenceNumber": {
          name: 'storySequenceNumber',
          type: 'number'
        },
        "imageUrls": {
          name: 'imageUrls',
          type: 'Array&lt;any&gt;'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
