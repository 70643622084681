//import { WorkshopRegistrationMarkPaymentButtonComponent } from './workshop-registration-mark-payment-button.component';

import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NbTabsetModule } from '@nebular/theme'; //Our modal uses the nb-tab element
import { PreviewMailModalComponent } from './preview-mail-modal.component';
import { PreviewMailModalService } from './preview-mail-modal.service';

export * from './mail-preview.interface';

@NgModule({
  imports: [CommonModule, NbTabsetModule],
  declarations: [PreviewMailModalComponent],
  providers: [PreviewMailModalService],
  //Our modal will not explicitely be called from the outside (only the service will be), but the error we get is - in hindsight - pretty self-explanatory:
  //  "No component factory found for PreviewMailModalComponent. Did you add it to @NgModule.entryComponents"
  entryComponents: [PreviewMailModalComponent], //And so I added it! Gone is the error!
  exports: []
})
export class PreviewMailModalModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: PreviewMailModalModule
    };
  }
}
