/* tslint:disable */

declare var Object: any;
export interface ELearningClassEnrollmentInterface {
  "relatedELearningClassId": any;
  "registrant": any;
  "purchasedFor"?: any;
  "tuition": any;
  "access"?: any;
  "handlingStatus"?: string;
  "invoiceStatus"?: string;
  "id"?: any;
}

export class ELearningClassEnrollment implements ELearningClassEnrollmentInterface {
  "relatedELearningClassId": any;
  "registrant": any;
  "purchasedFor": any;
  "tuition": any;
  "access": any;
  "handlingStatus": string;
  "invoiceStatus": string;
  "id": any;
  constructor(data?: ELearningClassEnrollmentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ELearningClassEnrollment`.
   */
  public static getModelName() {
    return "ELearningClassEnrollment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ELearningClassEnrollment for dynamic purposes.
  **/
  public static factory(data: ELearningClassEnrollmentInterface): ELearningClassEnrollment{
    return new ELearningClassEnrollment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ELearningClassEnrollment',
      plural: 'ELearningClassEnrollments',
      path: 'ELearningClassEnrollments',
      idName: 'id',
      properties: {
        "relatedELearningClassId": {
          name: 'relatedELearningClassId',
          type: 'any'
        },
        "registrant": {
          name: 'registrant',
          type: 'any'
        },
        "purchasedFor": {
          name: 'purchasedFor',
          type: 'any'
        },
        "tuition": {
          name: 'tuition',
          type: 'any'
        },
        "access": {
          name: 'access',
          type: 'any'
        },
        "handlingStatus": {
          name: 'handlingStatus',
          type: 'string'
        },
        "invoiceStatus": {
          name: 'invoiceStatus',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
