/* tslint:disable */

declare var Object: any;
export interface ProductInterface {
  "nl": any;
  "en": any;
  "imageUrls": Array<any>;
  "price": any;
  "stock": boolean;
  "releaseDate": Date;
  "id"?: any;
}

export class Product implements ProductInterface {
  "nl": any;
  "en": any;
  "imageUrls": Array<any>;
  "price": any;
  "stock": boolean;
  "releaseDate": Date;
  "id": any;
  constructor(data?: ProductInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Product`.
   */
  public static getModelName() {
    return "Product";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Product for dynamic purposes.
  **/
  public static factory(data: ProductInterface): Product{
    return new Product(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Product',
      plural: 'Products',
      path: 'Products',
      idName: 'id',
      properties: {
        "nl": {
          name: 'nl',
          type: 'any'
        },
        "en": {
          name: 'en',
          type: 'any'
        },
        "imageUrls": {
          name: 'imageUrls',
          type: 'Array&lt;any&gt;'
        },
        "price": {
          name: 'price',
          type: 'any'
        },
        "stock": {
          name: 'stock',
          type: 'boolean'
        },
        "releaseDate": {
          name: 'releaseDate',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
