import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImagesPanelComponent } from './images-panel.component';
import { SingleImagePanelComponent } from './single-image-panel.component';
import { SingleFilePanelComponent } from './single-file-panel.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ImagesPanelComponent, SingleImagePanelComponent, SingleFilePanelComponent],
  providers: [],
  entryComponents: [],
  exports: [ImagesPanelComponent, SingleImagePanelComponent, SingleFilePanelComponent]
})
export class ImageDroppingModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ImageDroppingModule,
      providers: []
    };
  }
}
