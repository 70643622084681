import { Injectable } from '@angular/core';
import { ConfirmModalService } from 'app/pages/components/buttons/confirmmodal';
import { ToasterService } from 'angular2-toaster';
import { OrderApi } from 'app/shared/sdk';

import { of as observableOf } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { PreviewMailModalService } from 'app/pages/components/preview-mail-modal/preview-mail-modal.service';
import { MailPreview } from 'app/pages/components/preview-mail-modal/mail-preview.interface';

export interface OrderPayment {
  amountPaid: number;
  amountDue: number;
}

@Injectable()
export class OrderPaymentModalService {
  constructor(
    private confirmModalService: ConfirmModalService,
    private toasterService: ToasterService,
    private orderService: OrderApi,
    private previewMailModalService: PreviewMailModalService
  ) {}

  async markPayment(order): Promise<OrderPayment> {
    return new Promise<OrderPayment>((resolve, reject) => {
      const { header, content, options } = this.getHeaderContentAndOptions(order);
      this.confirmModalService.confirm(header, content, options, chosenOptions => {
        //This gets called when the user confirms. It needs to return an observable, because the modal needs
        //  to be made aware when everything is handled, so it can close itself.
        let actuallyMarkPayment$ = this.actuallyMarkPayment(order.id, chosenOptions).pipe(
          tap((payment: OrderPayment) => {
            resolve(payment);

            if (payment) {
              this.toasterService.pop(
                'success',
                'Betaling is geregistreerd',
                `Er is geregistreerd dat de klant ${chosenOptions.amount} euro heeft betaald`
              );
            }
          }),
          catchError((err) => {
            this.toasterService.pop(
              'error',
              'Het is niet gelukt om de betaling te registreren',
            );

            reject();
            return observableOf(err);
          })
        );
        return actuallyMarkPayment$; //This callback function needs to return the observable so the modal will know when everything is handled, and it may close the modal.
      });
    });
  }

  private actuallyMarkPayment(orderId, chosenOptions) {
    if (!chosenOptions.amount || chosenOptions.amount < 0) {
      return observableOf(null);
    }
    return this.orderService
      .markPayment(orderId, chosenOptions.amount, chosenOptions.doSendEmails)
      .flatMap(({ success, amountDue }) => {
        const payment: OrderPayment = {
          amountPaid: chosenOptions.amount,
          amountDue: amountDue
        };
        return observableOf(payment);
      });
  }

  private getHeaderContentAndOptions(order) {
    const {
      bankPaymentCode,
      followUpNumber,
      amountDue,
      mainAddress: { firstName, lastName }
    } = order;
    return {
      header: 'De betaling van een bestelling registreren?',
      content: `
        <p>Doe dit enkel als klant <b>${firstName} ${lastName}</b> de bestelling met volgnummer <b>${followUpNumber}</b> betaald heeft?</p>
        <p>De code dewelke de klant gebruikt zou moeten hebben voor de betaling is <b>${bankPaymentCode}</b></p>
        <p>Wat zal er gebeuren?</p>
        <ul>
          <li>Geef hieronder in hoeveel de klant betaald heeft.</li>
          <li>Dit bedrag zal afgetrokken worden van het nog openstaande bedrag van de klant (${amountDue} euro)</li>
        </ul>`,
      options: [
        {
          name: 'amount',
          label: `Hoeveel euro heeft de klant betaald (het openstaand bedrag is ${amountDue} euro)?`,
          inputType: 'number',
          value: amountDue
        },
        {
          name: 'doSendEmails',
          label: 'Wil je de klant op de hoogte brengen dat we een betaling hebben ontvangen door een mail te sturen?',
          inputType: 'boolean',
          value: true
        },
        {
          name: 'checkEmails',
          label: 'Bekijk de mail die verstuurd zal worden',
          inputType: 'button',
          click: () => {
            this.orderService.getMailForMarkPayment(order.id).subscribe(({ mail, to }) => {
              const mailPreview: MailPreview = {
                to: to,
                html: mail
              };
              this.previewMailModalService.showMailPreview(mailPreview);
            });
          }
        }
      ]
    };
  }
}
