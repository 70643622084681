/* tslint:disable */

declare var Object: any;
export interface ELearningPhysicalResourceInterface {
  "name": string;
  "keywords"?: string;
  "description": string;
  "stock"?: boolean;
  "price"?: number;
  "weight"?: number;
  "casing"?: string;
  "retailPriceRange"?: Array<any>;
  "relatedELearningDigitalResourceIds"?: Array<any>;
  "isDeleted"?: boolean;
  "id"?: any;
}

export class ELearningPhysicalResource implements ELearningPhysicalResourceInterface {
  "name": string;
  "keywords": string;
  "description": string;
  "stock": boolean;
  "price": number;
  "weight": number;
  "casing": string;
  "retailPriceRange": Array<any>;
  "relatedELearningDigitalResourceIds": Array<any>;
  "isDeleted": boolean;
  "id": any;
  constructor(data?: ELearningPhysicalResourceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ELearningPhysicalResource`.
   */
  public static getModelName() {
    return "ELearningPhysicalResource";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ELearningPhysicalResource for dynamic purposes.
  **/
  public static factory(data: ELearningPhysicalResourceInterface): ELearningPhysicalResource{
    return new ELearningPhysicalResource(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ELearningPhysicalResource',
      plural: 'ELearningPhysicalResources',
      path: 'ELearningPhysicalResources',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "keywords": {
          name: 'keywords',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "stock": {
          name: 'stock',
          type: 'boolean'
        },
        "price": {
          name: 'price',
          type: 'number'
        },
        "weight": {
          name: 'weight',
          type: 'number'
        },
        "casing": {
          name: 'casing',
          type: 'string'
        },
        "retailPriceRange": {
          name: 'retailPriceRange',
          type: 'Array&lt;any&gt;'
        },
        "relatedELearningDigitalResourceIds": {
          name: 'relatedELearningDigitalResourceIds',
          type: 'Array&lt;any&gt;'
        },
        "isDeleted": {
          name: 'isDeleted',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
