import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LogApi } from '../../../shared/sdk';
import { ToasterService } from 'angular2-toaster';
// import 'rxjs/add/operator/mergeMap'; //flatMap

@Component({
  selector: 'snr-order-actions',
  templateUrl: './order-actions.component.html',
  styleUrls: ['./order-actions.component.scss']
})
export class OrderActionsComponent implements OnInit {
  @Input()
  order: any;
  @Output()
  change: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  busy: EventEmitter<boolean> = new EventEmitter<boolean>();

  logsGroupedByDay: any[];

  constructor(
    private loggerService: LogApi,
    //tslint:disable-next-line
    private toasterService: ToasterService
  ) {}

  ngOnInit() {}

  onPaid({ amountPaid, amountDue }): void {
    this.logsGroupedByDay = null;
    const whatHasChanged = {
      order: {
        id: this.order.id,
        amountDue: amountDue,
        paymentStatus: amountDue === 0 ? 'paymentReceived' : 'paymentDue',
        orderStatus: amountDue === 0 ? 'orderReceived' : 'orderInProgress'
      }
    };
    this.change.emit(whatHasChanged);
  }

  onInvoiceSent(): void {
    //We just marked that we've sent the invoice to the participant. To register this, we need to add an extra item to
    //  to the paymentLog, because that's what also happened on the server.
    if (!this.order.paymentLog) {
      this.order.paymentLog = [];
    }
    this.order.paymentLog.push({
      timestamp: new Date(), //It doesn't matter that this one is not exactly the same as the one in Mongo
      status: 'invoiceSent'
    });
    const whatHasChanged = {
      order: {
        id: this.order.id,
        paymentLog: this.order.paymentLog
      }
    };
    this.change.emit(whatHasChanged);
  }

  onAssembled(): void {
    this.logsGroupedByDay = null;
    const whatHasChanged = {
      order: {
        id: this.order.id,
        orderStatus: 'orderAssembled'
      }
    };
    this.change.emit(whatHasChanged);
  }

  onHandled(): void {
    this.logsGroupedByDay = null;
    const whatHasChanged = {
      order: {
        id: this.order.id,
        orderStatus: 'orderHandled'
      }
    };
    this.change.emit(whatHasChanged);
  }

  onTabChanged(event: any) {
    if (event.tabTitle === 'Log' && !this.logsGroupedByDay) {
      this.loggerService
        .find({
          where: {
            relatedOrderId: this.order.id
          }
        })
        .subscribe(logs => {
          this.logsGroupedByDay = <any[]>logs.reduce((memo: any[], log: any) => {
            const lastLoggedDay = memo.length ? memo[memo.length - 1].day.getTime() : null;
            let day = new Date(log.timestamp);
            day.setHours(0, 0, 0, 0);
            if (lastLoggedDay !== day.getTime()) {
              memo.push({
                day: day,
                logs: []
              });
            }
            memo[memo.length - 1].logs.unshift(log);
            return memo;
          }, []);
          this.logsGroupedByDay.reverse();
        });
    }
  }
}
