import { Input, Component, Output, EventEmitter } from '@angular/core';
import { ToasterService } from '../../../../../node_modules/angular2-toaster';
import { WorkshopRegistrationApi } from '../../../shared/sdk';
import { of as observableOf } from 'rxjs';
import { ConfirmModalService } from './confirmmodal';

import { PreviewMailModalService } from '../preview-mail-modal/preview-mail-modal.service';
import { MailPreview } from '../preview-mail-modal/mail-preview.interface';

@Component({
  selector: 'snr-confirm-workshop-registration-button',
  templateUrl: './confirm-workshop-registration-button.component.html',
  styleUrls: ['./buttons.scss']
})
export class ConfirmWorkshopRegistrationButtonComponent {
  @Input()
  workshopRegistration: any;
  @Input()
  workshopStatus: string;
  @Output()
  registered: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  participating: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private confirmModalService: ConfirmModalService,
    //tslint:disable-next-line
    private toasterService: ToasterService,
    //tslint:disable-next-line
    private workshopRegistrationService: WorkshopRegistrationApi,
    //tslint:disable-next-line
    private previewMailModalService: PreviewMailModalService
  ) {}

  onChangeStatusToRegistered(): void {
    const { header, content, options } = getHeaderContentAndOptions.call(this);
    this.confirmModalService.confirm(header, content, options, () => {
      //The only option is a button, which is not returning a value. So we con't need to handle chosenOptions.
      return changeStatusToRegistered.call(this);
    });

    function getHeaderContentAndOptions() {
      return {
        header: 'Inschrijving bevestigen?',
        content: `
          <p>Weet je zeker dat je status wilt veranderen naar 'geregistreerd'?</p>
          <p>Wat zal er gebeuren?</p>
          <ul>
            <li>De deelnemer zal een mail aankrijgen waarin staat dat de inschrijving bevestigd is (en dat de workshop nog niet vastligt)</li>
            <li>Wij krijgen een mailtje aan met de melding dat de deelnemer haar inschrijving bevestigd heeft</li>
          </ul>`,
        options: [
          {
            name: 'checkEmails',
            label: 'Bekijk de mails die verstuurd zullen worden',
            inputType: 'button',
            click: () => {
              this.workshopRegistrationService
                .getMailsForConfirmRegistrationByAdmin(
                  this.workshopRegistration.id
                )
                .subscribe(mails => {
                  const mailPreviews: MailPreview[] = mails.map(mail => {
                    return {
                      to: mail.to,
                      html: mail.html
                    };
                  });
                  this.previewMailModalService.showMailPreviews(mailPreviews);
                });
            }
          }
        ]
      };
    }

    function changeStatusToRegistered() {
      return this.workshopRegistrationService
        .confirmRegistrationByAdmin(this.workshopRegistration.id)
        .flatMap(success => {
          if (success) {
            this.workshopRegistration.status = 'registered';
            this.registered.emit();
            this.toasterService.pop(
              'success',
              'Inschrijving',
              'Inschrijving is bevestigd...'
            );
          }
          return observableOf(null);
        });
    }
  }

  onChangeStatusToParticipating(): void {
    const { header, content } = getHeaderAndContent.call(this);
    this.confirmModalService.confirm(header, content, null, () => {
      return changeStatusToParticipating.call(this);
    });

    function getHeaderAndContent() {
      return {
        header: 'Inschrijving bevestigen?',
        content: `
        <p>Weet je zeker dat je status wilt veranderen naar 'neemt deel'?</p>
        <p>Wat zal er gebeuren?</p>
        <ul>
          <li>De deelnemer zal een mail aankrijgen waarin staat dat de inschrijving bevestigd is (en dat de workshop door zal gaan)</li>
          <li>Wij krijgen een mailtje aan met de melding dat de deelnemer haar inschrijving bevestigd heeft</li>
          <li>Er zal aangegeven worden dat we nog een factuur moeten maken voor deze deelnemer</li>
        </ul>`
      };
    }

    function changeStatusToParticipating() {
      return this.workshopRegistrationService
        .confirmRegistrationByAdmin(this.workshopRegistration.id)
        .flatMap(success => {
          if (success) {
            this.workshopRegistration.status = 'participating';
            this.participating.emit();
            this.toasterService.pop(
              'success',
              'Inschrijving',
              'Inschrijving is bevestigd...'
            );
          }
          return observableOf(null);
        });
    }
  }
}
