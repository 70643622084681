import { Input, Component, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { ToasterService } from '../../../../../node_modules/angular2-toaster';
import { WorkshopRegistrationApi } from '../../../shared/sdk';
import { of as observableOf } from 'rxjs';
import { ConfirmModalService } from './confirmmodal';

import { PreviewMailModalService } from '../preview-mail-modal/preview-mail-modal.service';
import { MailPreview } from '../preview-mail-modal/mail-preview.interface';

import { sprintf } from 'sprintf-js';

@Component({
  selector: 'snr-workshop-registration-send-payment-information-button',
  templateUrl: './workshop-registration-send-payment-information-button.component.html',
  styleUrls: ['./buttons.scss']
})
export class WorkshopRegistrationSendPaymentInformationButtonComponent implements OnChanges {
  @Input()
  workshopRegistration: any;
  @Output()
  sent: EventEmitter<any> = new EventEmitter<any>();

  paymentMailHasBeenSentBefore: boolean = false;

  constructor(
    private confirmModalService: ConfirmModalService,
    //tslint:disable-next-line
    private toasterService: ToasterService,
    //tslint:disable-next-line
    private workshopRegistrationService: WorkshopRegistrationApi,
    //tslint:disable-next-line
    private previewMailModalService: PreviewMailModalService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) {
      return;
    }
    if (changes.workshopRegistration && changes.workshopRegistration.currentValue) {
      const wr = changes.workshopRegistration.currentValue;
      this.paymentMailHasBeenSentBefore = wr.paymentLog && wr.paymentLog.find(log => log.status === 'paymentRequested');
    }
  }

  onSendInitialPaymentInformation(): void {
    this.onSendPaymentInformation(true);
  }

  onSendPaymentInformation(forceInitialMail): void {
    const { header, content, options } = getHeaderContentAndOptions.call(this);
    this.confirmModalService.confirm(header, content, options, () => {
      return sendPaymentInformation.call(this);
    });

    function getHeaderContentAndOptions() {
      return {
        header: 'Betalingsinformatie mailen?',
        content: sprintf(
          `
          <p>Weet je zeker dat je de betalingsinformatie wilt mailen naar de cursist?</p>
          <p>Wat zal er gebeuren?</p>
          <ul>
            <li>De cursist krijgt een mail toegestuurd dat ze %d euro inschrijvingsgeld moet betalen. De mail bevat een detail-overzicht van haar inschrijving (aantal ingeschreven personen, bestelde extras,...)</li>
          </ul>`,
          this.workshopRegistration.amountDue
        ),
        options: [
          {
            name: 'checkEmails',
            label: 'Bekijk de mail die verstuurd zal worden',
            inputType: 'button',
            click: () => {
              this.workshopRegistrationService
                .getMailsForSendPaymentInformation(this.workshopRegistration.id, forceInitialMail || false)
                .subscribe(mails => {
                  const mailPreviews: MailPreview[] = mails.map(({ body, to }) => {
                    return {
                      html: body,
                      to: to
                    };
                  });
                  this.previewMailModalService.showMailPreviews(mailPreviews);
                });
            }
          }
        ]
      };
    }

    function sendPaymentInformation() {
      return this.workshopRegistrationService
        .sendPaymentInformation(this.workshopRegistration.id, forceInitialMail || false)
        .flatMap(sentPaymentInformation => {
          this.sent.emit(sentPaymentInformation);

          this.toasterService.pop(
            'success',
            'Mail is verstuurd',
            'De mail met de betalingsinformatie is onderweg naar de cursist...'
          );
          return observableOf(null);
        });
    }
  }
}
