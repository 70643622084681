import { Injectable } from '@angular/core';

@Injectable()
export class WorkshopRegistrationPriceService {
  constructor() {}

  calculate(workshop: any, workshopRegistration: any): number {
    let totalPrice =
      workshop.price *
      (1 +
        (workshopRegistration.additionalPersons
          ? workshopRegistration.additionalPersons.length
          : 0));
    if (workshop.providedExtras) {
      if (workshop.providedExtras.lunch) {
        totalPrice +=
          (workshopRegistration.joinLunch || 0) *
          workshop.dates.length *
          workshop.providedExtras.lunch.price;
      }
      if (workshop.providedExtras.startersKit) {
        totalPrice +=
          (workshopRegistration.orderStartersKit || 0) *
          workshop.providedExtras.startersKit.price;
      }
      if (workshop.providedExtras.varia1) {
        totalPrice +=
          (workshopRegistration.orderVaria1 || 0) *
          workshop.providedExtras.varia1.price;
      }
      if (workshop.providedExtras.varia2) {
        totalPrice +=
          (workshopRegistration.orderVaria2 || 0) *
          workshop.providedExtras.varia2.price;
      }
      if (workshop.providedExtras.varia3) {
        totalPrice +=
          (workshopRegistration.orderVaria3 || 0) *
          workshop.providedExtras.varia3.price;
      }
    }
    return totalPrice;
  }
}
