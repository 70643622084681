import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from '../../../../../node_modules/angular2-toaster';

@Component({
  selector: 'ngx-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  header: string;
  content: string = '';
  options: any[];
  buttons: any[];

  isBusy: boolean = false;

  constructor(private activeModal: NgbActiveModal, private toasterService: ToasterService) {}

  onOptionButtonClicked(optionButton: any): void {
    const possibleChosenOptions = this.options
      ? this.options.reduce((memo, option) => {
          if (optionButton.name !== option.name) {
            memo[option.name] = option.value;
          }
          return memo;
        }, {})
      : undefined;
    optionButton.click(possibleChosenOptions);
  }

  isOptionButtonDisabled(optionButton: any): boolean {
    if (!optionButton.isDisabled) {
      return false;
    }
    const possibleChosenOptions = this.options
      ? this.options.reduce((memo, option) => {
          if (optionButton.name !== option.name) {
            memo[option.name] = option.value;
          }
          return memo;
        }, {})
      : undefined;
    return optionButton.isDisabled(possibleChosenOptions);
  }

  onButtonClicked(button: any): void {
    if (button.asyncCallback) {
      this.isBusy = true;
      const possibleChosenOptions = this.convertToDictionary(this.options);
      let asyncAction$ = button.asyncCallback(possibleChosenOptions);
      asyncAction$.subscribe(
        () => {
          this.isBusy = false;
          this.activeModal.close();
        },
        err => {
          this.isBusy = false;
          this.toasterService.pop('error', 'Probleem', 'Actie is niet gelukt...');
          console.error(err);
        },
        () => {}
      );
    } else if (button.callback) {
      const possibleChosenOptions = this.convertToDictionary(this.options);
      button.callback(possibleChosenOptions);
      this.activeModal.close();
    } else {
      this.activeModal.close();
    }
  }

  private convertToDictionary(options) {
    if (!options) {
      return undefined;
    }
    const optionsDictionary = this.options.reduce((memo, option) => {
      memo[option.name] = option.value;
      return memo;
    }, {});
    return optionsDictionary;
  }

  onCloseButtonClicked(): void {
    let button = this.buttons.find(btn => {
      return btn.isCancelButton;
    });
    if (!button) {
      this.activeModal.close();
    } else if (button.asyncCallback) {
      let asyncAction$ = button.asyncCallback();
      asyncAction$.subscribe(
        () => {
          this.isBusy = false;
          this.activeModal.close();
        },
        err => {
          this.isBusy = false;
          this.toasterService.pop('error', 'Probleem', 'Sluitactie is niet gelukt...');
        },
        () => {}
      );
    } else if (button.callback) {
      button.callback();
      this.activeModal.close();
    } else {
      this.activeModal.close();
    }
  }
}
