import { Component, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'snr-mails-modal',
  templateUrl: './mails-modal.component.html',
  styleUrls: ['./mails-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush //Counters error 'Expression has changed after it was checked'
})
export class MailsModalComponent implements AfterViewInit {
  header: string;
  mails: any[] = [];
  isBusy: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private cdRef: ChangeDetectorRef //Counters error 'Expression has changed after it was checked'
  ) {}

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  onCloseButtonClicked(): void {
    this.activeModal.close();
  }
}
