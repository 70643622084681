import { Input, Component, Output, EventEmitter, OnInit } from '@angular/core';
import { OrderPaymentModalService, OrderPayment } from '../../../services/order/orderpaymentmodal';

@Component({
  selector: 'snr-order-mark-payment-button',
  templateUrl: './order-mark-payment-button.component.html'
})
export class OrderMarkPaymentButtonComponent implements OnInit {
  @Input()
  order: any;
  @Output()
  paid: EventEmitter<any> = new EventEmitter<any>();

  forceHideToCoverUpPaypalScrewUp: boolean = false;

  constructor(private orderPaymentModalService: OrderPaymentModalService) {}

  ngOnInit() {
    //Problem with paypal... need to fix this one day. For know, this is a hack to make it output correctly.
    if (this.order.paymentMethod === 'PAYPAL' && this.order.paymentStatus === 'paymentReceived' && this.order.amountDue > 0) {
      this.forceHideToCoverUpPaypalScrewUp = true;
    }
  }

  async onMarkPayment(): Promise<void> {
    const payment: OrderPayment = await this.orderPaymentModalService.markPayment(this.order).catch(err => {
      return null;
    });
    if (payment) {
      this.paid.emit(payment);
    }
  }
}
