/* tslint:disable */

declare var Object: any;
export interface CouponInterface {
  "code": string;
  "from"?: Date;
  "to"?: Date;
  "usageLimitation": number;
  "usageCount": number;
  "affectsProducts": boolean;
  "affectsShipping": boolean;
  "usableForELearningClasses": boolean;
  "productTotalPriceLowerLimit"?: number;
  "productTotalPriceUpperLimit"?: number;
  "percentageOrTotal": string;
  "amount": number;
  "disabled": boolean;
  "id"?: any;
}

export class Coupon implements CouponInterface {
  "code": string;
  "from": Date;
  "to": Date;
  "usageLimitation": number;
  "usageCount": number;
  "affectsProducts": boolean;
  "affectsShipping": boolean;
  "usableForELearningClasses": boolean;
  "productTotalPriceLowerLimit": number;
  "productTotalPriceUpperLimit": number;
  "percentageOrTotal": string;
  "amount": number;
  "disabled": boolean;
  "id": any;
  constructor(data?: CouponInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Coupon`.
   */
  public static getModelName() {
    return "Coupon";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Coupon for dynamic purposes.
  **/
  public static factory(data: CouponInterface): Coupon{
    return new Coupon(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Coupon',
      plural: 'Coupons',
      path: 'Coupons',
      idName: 'id',
      properties: {
        "code": {
          name: 'code',
          type: 'string'
        },
        "from": {
          name: 'from',
          type: 'Date',
          default: new Date(0)
        },
        "to": {
          name: 'to',
          type: 'Date',
          default: new Date(0)
        },
        "usageLimitation": {
          name: 'usageLimitation',
          type: 'number',
          default: 999999
        },
        "usageCount": {
          name: 'usageCount',
          type: 'number',
          default: 0
        },
        "affectsProducts": {
          name: 'affectsProducts',
          type: 'boolean'
        },
        "affectsShipping": {
          name: 'affectsShipping',
          type: 'boolean'
        },
        "usableForELearningClasses": {
          name: 'usableForELearningClasses',
          type: 'boolean'
        },
        "productTotalPriceLowerLimit": {
          name: 'productTotalPriceLowerLimit',
          type: 'number'
        },
        "productTotalPriceUpperLimit": {
          name: 'productTotalPriceUpperLimit',
          type: 'number'
        },
        "percentageOrTotal": {
          name: 'percentageOrTotal',
          type: 'string'
        },
        "amount": {
          name: 'amount',
          type: 'number'
        },
        "disabled": {
          name: 'disabled',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
