/* tslint:disable */

declare var Object: any;
export interface WorkshopPaymentInterface {
  "transactionId"?: string;
  "paymentUrl"?: string;
  "initiationTimestamp": Date;
  "handOverTimestamp"?: Date;
  "finishUpTimestamp"?: Date;
  "hookEvents"?: Array<any>;
  "metadata": any;
  "id"?: any;
}

export class WorkshopPayment implements WorkshopPaymentInterface {
  "transactionId": string;
  "paymentUrl": string;
  "initiationTimestamp": Date;
  "handOverTimestamp": Date;
  "finishUpTimestamp": Date;
  "hookEvents": Array<any>;
  "metadata": any;
  "id": any;
  constructor(data?: WorkshopPaymentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `WorkshopPayment`.
   */
  public static getModelName() {
    return "WorkshopPayment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of WorkshopPayment for dynamic purposes.
  **/
  public static factory(data: WorkshopPaymentInterface): WorkshopPayment{
    return new WorkshopPayment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'WorkshopPayment',
      plural: 'WorkshopPayments',
      path: 'WorkshopPayments',
      idName: 'id',
      properties: {
        "transactionId": {
          name: 'transactionId',
          type: 'string'
        },
        "paymentUrl": {
          name: 'paymentUrl',
          type: 'string'
        },
        "initiationTimestamp": {
          name: 'initiationTimestamp',
          type: 'Date'
        },
        "handOverTimestamp": {
          name: 'handOverTimestamp',
          type: 'Date'
        },
        "finishUpTimestamp": {
          name: 'finishUpTimestamp',
          type: 'Date'
        },
        "hookEvents": {
          name: 'hookEvents',
          type: 'Array&lt;any&gt;'
        },
        "metadata": {
          name: 'metadata',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
