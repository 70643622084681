import { Input, Component, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ToasterService } from '../../../../../node_modules/angular2-toaster';
import { OrderInvoiceModalService } from '../../../services/order/orderinvoicemodal';

//NOTE: for now, we don't actually send the invoice - which we need to make manually - via this UI. Instead, we just mark the order so we can see
//  for which ones we've already sent the invoice.

@Component({
  selector: 'snr-order-invoice-sent-button',
  templateUrl: './order-invoice-sent-button.component.html',
  styleUrls: ['./buttons.scss']
})
export class OrderInvoiceSentButtonComponent implements OnChanges {
  @Input()
  order: any;
  @Output()
  sent: EventEmitter<void> = new EventEmitter<void>();

  invoiceAlreadySent: boolean = false;

  constructor(private orderInvoiceModalService: OrderInvoiceModalService, private toasterService: ToasterService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) {
      return;
    }
    if (changes.order && changes.order.currentValue) {
      const order = changes.order.currentValue;
      this.invoiceAlreadySent = order.paymentLog && order.paymentLog.some(log => log.status === 'invoiceSent');
    }
  }

  async onInvoiceSent(): Promise<void> {
    try {
      await this.orderInvoiceModalService.markInvoiceSent(this.order);
      this.invoiceAlreadySent = true;
      this.sent.emit();
    } catch (err) {}
  }

  copyCompanyDataToClipboard() {
    const companyDataText = `${this.order.company.name}
${this.order.company.address.streetAndNr}
${this.order.company.address.postalCodeAndCity}
${this.order.company.address.country}
${this.order.company.vatNumber}`;
    this.copyToClipboard(companyDataText);
  }

  copyOrderDataToClipboard() {
    let orderDataText = '...';
    this.copyToClipboard(orderDataText);
  }

  private copyToClipboard(text) {
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.toasterService.pop('success', 'Tekst', 'Tekst is gekopieerd naar het clipboard...');
  }
}
