/* tslint:disable */

declare var Object: any;
export interface OrderInterface {
  "timestamp": Date;
  "followUpNumber": number;
  "ipAddress": string;
  "language": string;
  "orderStatus": string;
  "emailAddress"?: string;
  "cartItems": any;
  "shipping"?: any;
  "coupon"?: any;
  "customerType": string;
  "company"?: any;
  "mainAddress": any;
  "deliveryAddressType": string;
  "deliveryAddress"?: any;
  "paymentMethod": string;
  "paymentStatus": string;
  "bankPaymentCode"?: string;
  "id"?: any;
}

export class Order implements OrderInterface {
  "timestamp": Date;
  "followUpNumber": number;
  "ipAddress": string;
  "language": string;
  "orderStatus": string;
  "emailAddress": string;
  "cartItems": any;
  "shipping": any;
  "coupon": any;
  "customerType": string;
  "company": any;
  "mainAddress": any;
  "deliveryAddressType": string;
  "deliveryAddress": any;
  "paymentMethod": string;
  "paymentStatus": string;
  "bankPaymentCode": string;
  "id": any;
  constructor(data?: OrderInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Order`.
   */
  public static getModelName() {
    return "Order";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Order for dynamic purposes.
  **/
  public static factory(data: OrderInterface): Order{
    return new Order(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Order',
      plural: 'Orders',
      path: 'Orders',
      idName: 'id',
      properties: {
        "timestamp": {
          name: 'timestamp',
          type: 'Date',
          default: new Date(0)
        },
        "followUpNumber": {
          name: 'followUpNumber',
          type: 'number'
        },
        "ipAddress": {
          name: 'ipAddress',
          type: 'string'
        },
        "language": {
          name: 'language',
          type: 'string'
        },
        "orderStatus": {
          name: 'orderStatus',
          type: 'string'
        },
        "emailAddress": {
          name: 'emailAddress',
          type: 'string'
        },
        "cartItems": {
          name: 'cartItems',
          type: 'any'
        },
        "shipping": {
          name: 'shipping',
          type: 'any'
        },
        "coupon": {
          name: 'coupon',
          type: 'any'
        },
        "customerType": {
          name: 'customerType',
          type: 'string'
        },
        "company": {
          name: 'company',
          type: 'any'
        },
        "mainAddress": {
          name: 'mainAddress',
          type: 'any'
        },
        "deliveryAddressType": {
          name: 'deliveryAddressType',
          type: 'string'
        },
        "deliveryAddress": {
          name: 'deliveryAddress',
          type: 'any'
        },
        "paymentMethod": {
          name: 'paymentMethod',
          type: 'string'
        },
        "paymentStatus": {
          name: 'paymentStatus',
          type: 'string'
        },
        "bankPaymentCode": {
          name: 'bankPaymentCode',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
