/* tslint:disable */
import {
  Log
} from '../index';

declare var Object: any;
export interface WorkshopRegistrationBackupInterface {
  "relatedWorkshopId": any;
  "relatedWorkshopSubjectId": any;
  "firstName": string;
  "lastName": string;
  "emailAddress": string;
  "hashedEmailAddress": string;
  "phoneNumber": string;
  "information"?: string;
  "timestamp": Date;
  "language": string;
  "id"?: any;
  Logs?: Log[];
}

export class WorkshopRegistrationBackup implements WorkshopRegistrationBackupInterface {
  "relatedWorkshopId": any;
  "relatedWorkshopSubjectId": any;
  "firstName": string;
  "lastName": string;
  "emailAddress": string;
  "hashedEmailAddress": string;
  "phoneNumber": string;
  "information": string;
  "timestamp": Date;
  "language": string;
  "id": any;
  Logs: Log[];
  constructor(data?: WorkshopRegistrationBackupInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `WorkshopRegistrationBackup`.
   */
  public static getModelName() {
    return "WorkshopRegistrationBackup";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of WorkshopRegistrationBackup for dynamic purposes.
  **/
  public static factory(data: WorkshopRegistrationBackupInterface): WorkshopRegistrationBackup{
    return new WorkshopRegistrationBackup(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'WorkshopRegistrationBackup',
      plural: 'WorkshopRegistrationBackups',
      path: 'WorkshopRegistrationBackups',
      idName: 'id',
      properties: {
        "relatedWorkshopId": {
          name: 'relatedWorkshopId',
          type: 'any'
        },
        "relatedWorkshopSubjectId": {
          name: 'relatedWorkshopSubjectId',
          type: 'any'
        },
        "firstName": {
          name: 'firstName',
          type: 'string'
        },
        "lastName": {
          name: 'lastName',
          type: 'string'
        },
        "emailAddress": {
          name: 'emailAddress',
          type: 'string'
        },
        "hashedEmailAddress": {
          name: 'hashedEmailAddress',
          type: 'string'
        },
        "phoneNumber": {
          name: 'phoneNumber',
          type: 'string'
        },
        "information": {
          name: 'information',
          type: 'string'
        },
        "timestamp": {
          name: 'timestamp',
          type: 'Date',
          default: new Date(0)
        },
        "language": {
          name: 'language',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        Logs: {
          name: 'Logs',
          type: 'Log[]',
          model: 'Log',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'relatedWorkshopRegistrationBackupId'
        },
      }
    }
  }
}
