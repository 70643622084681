import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LogApi, WorkshopRegistrationApi } from '../../../shared/sdk';
import { ToasterService } from 'angular2-toaster';
// import 'rxjs/add/operator/mergeMap'; //flatMap

@Component({
  selector: 'snr-workshopregistrationbackup-actions',
  templateUrl: './workshopregistrationbackup-actions.component.html',
  styleUrls: ['./workshopregistrationbackup-actions.component.scss']
})
export class WorkshopRegistrationBackupActionsComponent implements OnInit {
  @Input()
  workshopSubject: any;
  @Input()
  workshop: any;
  @Input()
  workshopRegistrationBackup: any;
  @Output()
  change: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  busy: EventEmitter<boolean> = new EventEmitter<boolean>();

  logsGroupedByDay: any[];

  constructor(
    private loggerService: LogApi,
    //tslint:disable-next-line
    private workshopRegistrationService: WorkshopRegistrationApi,
    //tslint:disable-next-line
    private toasterService: ToasterService
  ) {}

  ngOnInit() {}

  onRegistrationLinkSent(): void {
    // this.workshopRegistration._receivedInfoMail = true;
    // const whatHasChanged = {
    //   workshopRegistration: {
    //     id: this.workshopRegistration.id,
    //     relatedWorkshopId: this.workshopRegistration.relatedWorkshopId,
    //     _receivedInfoMail: true
    //   }
    // };
    // this.change.emit(whatHasChanged);
  }

  onTabChanged(event: any) {
    if (event.tabTitle === 'Log' && !this.logsGroupedByDay) {
      this.loggerService
        .find({
          where: {
            relatedWorkshopRegistrationBackupId: this.workshopRegistrationBackup.id
          }
        })
        .subscribe(logs => {
          this.logsGroupedByDay = <any[]>logs.reduce((memo: any[], log: any) => {
            const lastLoggedDay = memo.length ? memo[memo.length - 1].day.getTime() : null;
            let day = new Date(log.timestamp);
            day.setHours(0, 0, 0, 0);
            if (lastLoggedDay !== day.getTime()) {
              memo.push({
                day: day,
                logs: []
              });
            }
            memo[memo.length - 1].logs.unshift(log);
            return memo;
          }, []);
          this.logsGroupedByDay.reverse();
        });
    }
  }
}
