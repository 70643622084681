import { Input, Component, Output, EventEmitter } from '@angular/core';
import { ToasterService } from '../../../../../node_modules/angular2-toaster';
import { WorkshopRegistrationApi } from '../../../shared/sdk';
import { of as observableOf } from 'rxjs';
import { ConfirmModalService } from './confirmmodal';

import { sprintf } from 'sprintf-js';

@Component({
  selector: 'snr-workshop-registration-determine-refund-amount-button',
  templateUrl:
    './workshop-registration-determine-refund-amount-button.component.html',
  styleUrls: ['./buttons.scss']
})
export class WorkshopRegistrationDetermineRefundAmountButtonComponent {
  @Input()
  workshopRegistration: any;
  @Output()
  refundDetermined: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private confirmModalService: ConfirmModalService,
    //tslint:disable-next-line
    private toasterService: ToasterService,
    //tslint:disable-next-line
    private workshopRegistrationService: WorkshopRegistrationApi
  ) {}

  calculateNettoAmountReceived(): number {
    //We can't calculate it once and keep that value, because while this button is alive, the paymentLog might get updated, and then a pre-calculated
    //  value would be wrong.
    if (!this.workshopRegistration.paymentLog) {
      return 0;
    }
    return this.workshopRegistration.paymentLog.reduce((memo, log) => {
      if (log.status === 'paymentReceived') {
        memo += log.amount;
      } else if (log.status === 'refundDetermined') {
        //This one is different; we've manually determined the refund amount. We might have kept 5 euro administration cost.
        //  So, no += or -=, just assign
        memo = log.amount;
      } else if (log.status === 'refundSent') {
        memo -= log.amount;
      }
      return memo;
    }, 0);
  }

  onDetermineRefundAmount(): void {
    const { header, content, options } = getHeaderContentAndOptions.call(this);
    this.confirmModalService.confirm(
      header,
      content,
      options,
      chosenOptions => {
        return determineRefundAmount.call(this, chosenOptions);
      }
    );

    function getHeaderContentAndOptions() {
      const amountPaidByFormerParticipant = this.workshopRegistration.paymentLog.reduce(
        (memo, log) => {
          if (log.status === 'paymentReceived') {
            memo += log.amount;
          }
          return memo;
        },
        0
      );
      let modalContent;
      if (this.workshopRegistration.status === 'cancelled') {
        modalContent = sprintf(
          `<p>Aangezien wij zelf geannuleerd hebben, moeten we de cursist het volledig bedrag (%d euro) terugbetalen</p>
         <p>Wat zal er gebeuren?</p>
         <ul>
           <li>Noteer hieronder hoeveel we gaan terugbetalen (het volledige bedrag).</li>
           <li>De betaling zelf moet natuurlijk apart gebeuren via de bank</li>
         </ul>`,
          amountPaidByFormerParticipant
        );
      } else if (this.workshopRegistration.status === 'revoked') {
        if (amountPaidByFormerParticipant === 0) {
          modalContent = `<p>De cursist heeft zelf geannuleerd, maar heeft nog niets betaald</p>
           <p>Er valt dus niets terug te betalen, maar erachter gaan voor een paar euro administratiekosten is ook de moeite niet.</p>`;
        } else {
          modalContent = sprintf(
            `<p>De cursist heeft zelf geannuleerd en heeft %d euro betaald (en moet nog %d euro betalen)</p>
          <p>Wat zal er gebeuren?</p>
          <ul>
            <li>Noteer hieronder hoeveel we gaan terugbetalen (aangewezen bedrag = %d euro omdat we 5 euro administratiekosten kunnen aanrekenen).</li>
            <li>De betaling zelf moet natuurlijk apart gebeuren via de bank</li>
          </ul>`,
            amountPaidByFormerParticipant,
            this.workshopRegistration.amountDue,
            amountPaidByFormerParticipant - 5
          );
        }
      }
      return {
        header: 'Bepalen hoeveel je gaat terugbetalen?',
        content: modalContent,
        options: [
          {
            name: 'amount',
            label: 'Hoeveel euro zullen we terugbetalen?',
            inputType: 'number',
            value: true
          }
          // {
          //   name: 'doSendEmails',
          //   label:
          //     'Wil je de deelnemer op de hoogte brengen dat we haar betaling hebben ontvangen door haar een mail te sturen?',
          //   inputType: 'boolean',
          //   value: true
          // }
        ]
      };
    }

    function determineRefundAmount(chosenOptions) {
      return this.workshopRegistrationService
        .determineRefundAmount(
          this.workshopRegistration.id,
          chosenOptions.amount
        )
        .flatMap(success => {
          this.refundDetermined.emit(chosenOptions.amount);

          this.toasterService.pop(
            'success',
            'Bedrag van de terugbetaling is vastgelegd',
            'Er is geregistreerd dat we de cursist ' +
              chosenOptions.amount +
              ' euro zullen terugbetalen'
          );
          return observableOf(null);
        });
    }
  }
}
