/* tslint:disable */
/**
* @module SDKModule
* @author Jonathan Casarrubias <t:@johncasarrubias> <gh:jonathan-casarrubias>
* @license MIT 2016 Jonathan Casarrubias
* @version 2.1.0
* @description
* The SDKModule is a generated Software Development Kit automatically built by
* the LoopBack SDK Builder open source module.
*
* The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
* can import this Software Development Kit as follows:
*
*
* APP Route Module Context
* ============================================================================
* import { NgModule }       from '@angular/core';
* import { BrowserModule }  from '@angular/platform-browser';
* // App Root 
* import { AppComponent }   from './app.component';
* // Feature Modules
* import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
* // Import Routing
* import { routing }        from './app.routing';
* @NgModule({
*  imports: [
*    BrowserModule,
*    routing,
*    SDK[Browser|Node|Native]Module.forRoot()
*  ],
*  declarations: [ AppComponent ],
*  bootstrap:    [ AppComponent ]
* })
* export class AppModule { }
*
**/
import { ErrorHandler } from './services/core/error.service';
import { LoopBackAuth } from './services/core/auth.service';
import { LoggerService } from './services/custom/logger.service';
import { SDKModels } from './services/custom/SDKModels';
import { InternalStorage, SDKStorage } from './storage/storage.swaps';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CookieBrowser } from './storage/cookie.browser';
import { StorageBrowser } from './storage/storage.browser';
import { UserApi } from './services/custom/User';
import { OrderApi } from './services/custom/Order';
import { VATApi } from './services/custom/VAT';
import { InquiryApi } from './services/custom/Inquiry';
import { ProductApi } from './services/custom/Product';
import { CouponApi } from './services/custom/Coupon';
import { StoryApi } from './services/custom/Story';
import { PointOfSaleApi } from './services/custom/PointOfSale';
import { WorkshopApi } from './services/custom/Workshop';
import { WorkshopSubjectApi } from './services/custom/WorkshopSubject';
import { WorkshopRegistrationApi } from './services/custom/WorkshopRegistration';
import { WorkshopRegistrationBackupApi } from './services/custom/WorkshopRegistrationBackup';
import { LogApi } from './services/custom/Log';
import { MoodboardApi } from './services/custom/Moodboard';
import { NewsletterSubscriptionApi } from './services/custom/NewsletterSubscription';
import { CustomerApi } from './services/custom/Customer';
import { BookApi } from './services/custom/Book';
import { ELearningDigitalResourceApi } from './services/custom/ELearningDigitalResource';
import { ELearningPhysicalResourceApi } from './services/custom/ELearningPhysicalResource';
import { ELearningLessonApi } from './services/custom/ELearningLesson';
import { ELearningClassApi } from './services/custom/ELearningClass';
import { ELearningClassContentApi } from './services/custom/ELearningClassContent';
import { ELearningClassSummaryApi } from './services/custom/ELearningClassSummary';
import { ELearningClassEnrollmentApi } from './services/custom/ELearningClassEnrollment';
import { WorkshopPaymentApi } from './services/custom/WorkshopPayment';
import { ELearningClassEnrollmentLimitApi } from './services/custom/ELearningClassEnrollmentLimit';
import { ELearningClassEnrollmentBackupApi } from './services/custom/ELearningClassEnrollmentBackup';
/**
* @module SDKBrowserModule
* @description
* This module should be imported when building a Web Application in the following scenarios:
*
*  1.- Regular web application
*  2.- Angular universal application (Browser Portion)
*  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
**/
@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  declarations: [ ],
  exports:      [ ],
  providers:    [
    ErrorHandler
  ]
})
export class SDKBrowserModule {
  static forRoot(internalStorageProvider: any = {
    provide: InternalStorage,
    useClass: CookieBrowser
  }): ModuleWithProviders {
    return {
      ngModule  : SDKBrowserModule,
      providers : [
        LoopBackAuth,
        LoggerService,
        SDKModels,
        UserApi,
        OrderApi,
        VATApi,
        InquiryApi,
        ProductApi,
        CouponApi,
        StoryApi,
        PointOfSaleApi,
        WorkshopApi,
        WorkshopSubjectApi,
        WorkshopRegistrationApi,
        WorkshopRegistrationBackupApi,
        LogApi,
        MoodboardApi,
        NewsletterSubscriptionApi,
        CustomerApi,
        BookApi,
        ELearningDigitalResourceApi,
        ELearningPhysicalResourceApi,
        ELearningLessonApi,
        ELearningClassApi,
        ELearningClassContentApi,
        ELearningClassSummaryApi,
        ELearningClassEnrollmentApi,
        WorkshopPaymentApi,
        ELearningClassEnrollmentLimitApi,
        ELearningClassEnrollmentBackupApi,
        internalStorageProvider,
        { provide: SDKStorage, useClass: StorageBrowser }
      ]
    };
  }
}
/**
* Have Fun!!!
* - Jon
**/
export * from './models/index';
export * from './services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export { CookieBrowser } from './storage/cookie.browser';
export { StorageBrowser } from './storage/storage.browser';

