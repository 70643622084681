import { Injectable } from '@angular/core';
import { NgbModal } from '../../../../../node_modules/@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../../@theme/components';

@Injectable()
export class ConfirmModalService {
  constructor(private modalService: NgbModal) {}

  confirm(header, content, options, asyncCallbackFn): void {
    const activeModal = this.modalService.open(ModalComponent, {
      size: 'lg',
      container: 'nb-layout'
    });
    Object.assign(activeModal.componentInstance, {
      header: header,
      content: content,
      options: options,
      buttons: [
        {
          text: 'Nee',
          class: 'btn-outline-primary',
          isCancelButton: true
        },
        {
          text: 'Ja',
          class: 'btn-primary',
          asyncCallback: asyncCallbackFn
        }
      ]
    });
  }
}
