/* tslint:disable */

declare var Object: any;
export interface ELearningClassEnrollmentBackupInterface {
  "relatedWorkshopId": any;
  "relatedWorkshopSubjectId": any;
  "firstName": string;
  "lastName": string;
  "emailAddress": string;
  "hashedEmailAddress": string;
  "phoneNumber": string;
  "information"?: string;
  "timestamp": Date;
  "language": string;
  "id"?: any;
}

export class ELearningClassEnrollmentBackup implements ELearningClassEnrollmentBackupInterface {
  "relatedWorkshopId": any;
  "relatedWorkshopSubjectId": any;
  "firstName": string;
  "lastName": string;
  "emailAddress": string;
  "hashedEmailAddress": string;
  "phoneNumber": string;
  "information": string;
  "timestamp": Date;
  "language": string;
  "id": any;
  constructor(data?: ELearningClassEnrollmentBackupInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ELearningClassEnrollmentBackup`.
   */
  public static getModelName() {
    return "ELearningClassEnrollmentBackup";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ELearningClassEnrollmentBackup for dynamic purposes.
  **/
  public static factory(data: ELearningClassEnrollmentBackupInterface): ELearningClassEnrollmentBackup{
    return new ELearningClassEnrollmentBackup(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ELearningClassEnrollmentBackup',
      plural: 'ELearningClassEnrollmentBackups',
      path: 'ELearningClassEnrollmentBackups',
      idName: 'id',
      properties: {
        "relatedWorkshopId": {
          name: 'relatedWorkshopId',
          type: 'any'
        },
        "relatedWorkshopSubjectId": {
          name: 'relatedWorkshopSubjectId',
          type: 'any'
        },
        "firstName": {
          name: 'firstName',
          type: 'string'
        },
        "lastName": {
          name: 'lastName',
          type: 'string'
        },
        "emailAddress": {
          name: 'emailAddress',
          type: 'string'
        },
        "hashedEmailAddress": {
          name: 'hashedEmailAddress',
          type: 'string'
        },
        "phoneNumber": {
          name: 'phoneNumber',
          type: 'string'
        },
        "information": {
          name: 'information',
          type: 'string'
        },
        "timestamp": {
          name: 'timestamp',
          type: 'Date',
          default: new Date(0)
        },
        "language": {
          name: 'language',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
