import { Input, Component, Output, EventEmitter } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { OrderApi } from '../../../shared/sdk';
import { of as observableOf } from 'rxjs';
import { ConfirmModalService } from './confirmmodal';

import { PreviewMailModalService } from '../preview-mail-modal/preview-mail-modal.service';
import { MailPreview } from '../preview-mail-modal/mail-preview.interface';

@Component({
  selector: 'snr-order-mark-as-handled-button',
  templateUrl: './order-mark-as-handled-button.component.html'
})
export class OrderMarkAsHandledButtonComponent {
  @Input()
  order: any;
  @Output()
  handled: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private confirmModalService: ConfirmModalService,
    //tslint:disable-next-line
    private toasterService: ToasterService,
    //tslint:disable-next-line
    private orderService: OrderApi,
    //tslint:disable-next-line
    private previewMailModalService: PreviewMailModalService
  ) {}

  onMarkAsHandled(): void {
    const { header, content, options } = getHeaderContentAndOptions.call(this);
    this.confirmModalService.confirm(header, content, options, chosenOptions => {
      return markAsHandled.call(this, chosenOptions);
    });

    function getHeaderContentAndOptions() {
      if (this.order.shipping.operator === 'PICKUP') {
        return {
          header: 'Is de bestelling opgehaald?',
          content: `
            <p>Wat zal er gebeuren?</p>
            <ul>
              <li>Bij de bestelling zal nu staan dat het opgehaald is, en dat de bestelling dus volledig afgehandeld is</li>
            </ul>`,
          options: [
            {
              name: 'doSendEmails',
              label: 'Wil je de klant nog een mailtje sturen om haar te bedanken voor de bestelling?',
              inputType: 'boolean',
              value: true
            },
            {
              name: 'checkEmails',
              label: 'Bekijk de mail die verstuurd zal worden',
              inputType: 'button',
              click: () => {
                this.orderService.getMailForMarkAsHandled(this.order.id).subscribe(({ body, to }) => {
                  const mailPreview: MailPreview = {
                    to: to,
                    html: body
                  };
                  this.previewMailModalService.showMailPreview(mailPreview);
                });
              }
            }
          ]
        };
      }
      return {
        header: 'Is de bestelling verzonden?',
        content: `
          <p>Wat zal er gebeuren?</p>
          <ul>
            <li>Bij de bestelling zal nu staan dat het verzonden is, en dat de bestelling dus volledig afgehandeld is</li>
          </ul>`,
        options: [
          {
            name: 'doSendEmails',
            label: 'Wil je de klant op de hoogte brengen dat het pakje net verstuurd is?',
            inputType: 'boolean',
            value: true
          },
          {
            name: 'checkEmails',
            label: 'Bekijk de mail die verstuurd zal worden',
            inputType: 'button',
            click: () => {
              this.orderService.getMailForMarkAsHandled(this.order.id).subscribe(({ mail, to }) => {
                const mailPreview: MailPreview = {
                  to: to,
                  html: mail
                };
                this.previewMailModalService.showMailPreview(mailPreview);
              });
            }
          }
        ]
      };
    }

    function markAsHandled(chosenOptions) {
      return this.orderService.markAsHandled(this.order.id, chosenOptions.doSendEmails).flatMap(success => {
        this.handled.emit();

        this.toasterService.pop('success', 'Bestelling is aangepast', 'De bestelling is volledig afgehandeld');
        return observableOf(null);
      });
    }
  }
}
