import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'snr-workshopregistration-actions-modal',
  template: `
    <div class="modal-header" [ngClass]="{ 'is-busy': isBusy }">
      <span>
        <h3>Workshopregistratie acties</h3>
        <small
          ><b>Cursist</b>: {{ workshopRegistration.firstName }} {{ workshopRegistration.lastName }} (<a
            href="mailto:{{ workshopRegistration.emailAddress }}"
            >{{ workshopRegistration.emailAddress }}</a
          >)</small
        ><br />
        <small><b>Onderwerp</b>: {{ workshopSubject.nl.name }}</small
        ><br />
        <small><b>Startdatum</b>: {{ workshop.dates[0].start | date: 'EEEE d-M-y':'':'nl' }}</small>
      </span>
      <button class="close" aria-label="Close" (click)="onCloseButtonClicked()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="max-height: calc(100vh - 250px); overflow-y: auto;">
      <snr-workshopregistration-actions
        [workshopSubject]="workshopSubject"
        [workshop]="workshop"
        [workshopRegistration]="workshopRegistration"
        (change)="onWorkshopRegistrationChanged($event)"
        (busy)="onBusy($event)"
      >
      </snr-workshopregistration-actions>
    </div>
    <div class="modal-footer">
      <button class="btn btn-md btn-primary" (click)="onCloseButtonClicked()">Sluiten</button>
    </div>
  `,
  styles: [
    `
      .modal-header.is-busy::after {
        animation: modalIsBusy 1s linear infinite;
        content: '.';
        font-size: 18em;
        padding-left: 35%;
        z-index: 1;
        position: absolute;
        top: -1px;
        left: -1px;
        bottom: -1px;
        right: -1px;
        background-color: rgba(0, 0, 0, 0.5);
        border: 1px solid #666;
        transition: 200ms content linear;
      }
    `
  ]
})
export class WorkshopRegistrationActionsModalComponent {
  workshopSubject: any;
  workshop: any;
  workshopRegistration: any;
  change: Function;

  isBusy: boolean = false;

  constructor(private activeModal: NgbActiveModal) {}

  onCloseButtonClicked(): void {
    this.activeModal.close();
  }

  onWorkshopRegistrationChanged(event): void {
    if (this.change) {
      this.change(event);
    }
  }

  onBusy(event): void {
    this.isBusy = event;
  }
}
