/* tslint:disable */

declare var Object: any;
export interface ELearningClassEnrollmentLimitInterface {
  "relatedELearningClassId": any;
  "maxEnrollmentCount": number;
  "id"?: any;
}

export class ELearningClassEnrollmentLimit implements ELearningClassEnrollmentLimitInterface {
  "relatedELearningClassId": any;
  "maxEnrollmentCount": number;
  "id": any;
  constructor(data?: ELearningClassEnrollmentLimitInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ELearningClassEnrollmentLimit`.
   */
  public static getModelName() {
    return "ELearningClassEnrollmentLimit";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ELearningClassEnrollmentLimit for dynamic purposes.
  **/
  public static factory(data: ELearningClassEnrollmentLimitInterface): ELearningClassEnrollmentLimit{
    return new ELearningClassEnrollmentLimit(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ELearningClassEnrollmentLimit',
      plural: 'ELearningClassEnrollmentLimits',
      path: 'ELearningClassEnrollmentLimits',
      idName: 'id',
      properties: {
        "relatedELearningClassId": {
          name: 'relatedELearningClassId',
          type: 'any'
        },
        "maxEnrollmentCount": {
          name: 'maxEnrollmentCount',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
