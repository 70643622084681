/* tslint:disable */
import {
  Workshop,
  WorkshopRegistration,
  Moodboard
} from '../index';

declare var Object: any;
export interface WorkshopSubjectInterface {
  "isReferredToAsCourse": boolean;
  "bannerUrl": string;
  "thumbnailUrl": string;
  "imageUrls"?: any;
  "nl"?: any;
  "en"?: any;
  "id"?: any;
  Workshops?: Workshop[];
  WorkshopRegistrations?: WorkshopRegistration[];
  Moodboards?: Moodboard[];
}

export class WorkshopSubject implements WorkshopSubjectInterface {
  "isReferredToAsCourse": boolean;
  "bannerUrl": string;
  "thumbnailUrl": string;
  "imageUrls": any;
  "nl": any;
  "en": any;
  "id": any;
  Workshops: Workshop[];
  WorkshopRegistrations: WorkshopRegistration[];
  Moodboards: Moodboard[];
  constructor(data?: WorkshopSubjectInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `WorkshopSubject`.
   */
  public static getModelName() {
    return "WorkshopSubject";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of WorkshopSubject for dynamic purposes.
  **/
  public static factory(data: WorkshopSubjectInterface): WorkshopSubject{
    return new WorkshopSubject(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'WorkshopSubject',
      plural: 'WorkshopSubjects',
      path: 'WorkshopSubjects',
      idName: 'id',
      properties: {
        "isReferredToAsCourse": {
          name: 'isReferredToAsCourse',
          type: 'boolean'
        },
        "bannerUrl": {
          name: 'bannerUrl',
          type: 'string'
        },
        "thumbnailUrl": {
          name: 'thumbnailUrl',
          type: 'string'
        },
        "imageUrls": {
          name: 'imageUrls',
          type: 'any'
        },
        "nl": {
          name: 'nl',
          type: 'any'
        },
        "en": {
          name: 'en',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        Workshops: {
          name: 'Workshops',
          type: 'Workshop[]',
          model: 'Workshop',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'relatedWorkshopSubjectId'
        },
        WorkshopRegistrations: {
          name: 'WorkshopRegistrations',
          type: 'WorkshopRegistration[]',
          model: 'WorkshopRegistration',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'relatedWorkshopSubjectId'
        },
        Moodboards: {
          name: 'Moodboards',
          type: 'Moodboard[]',
          model: 'Moodboard',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'relatedWorkshopSubjectId'
        },
      }
    }
  }
}
