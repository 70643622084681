import { Input, Component, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ToasterService } from '../../../../../node_modules/angular2-toaster';
import { ELearningClassEnrollmentInvoiceModalService } from '../../../services/elearningclassenrollment/elearningclassenrollmentinvoicemodal';

//NOTE: for now, we don't actually send the invoice - which we need to make manually - via this UI. Instead, we just mark the order so we can see
//  for which ones we've already sent the invoice.

@Component({
  selector: 'snr-elearning-class-enrollment-invoice-sent-button',
  templateUrl: './elearning-class-enrollment-invoice-sent-button.component.html',
  styleUrls: ['./buttons.scss']
})
export class ELearningClassEnrollmentInvoiceSentButtonComponent implements OnChanges {
  @Input()
  eLearningClassEnrollment: any;
  @Output()
  sent: EventEmitter<void> = new EventEmitter<void>();

  invoiceAlreadySent: boolean = false;

  constructor(private eLearningClassEnrollmentInvoiceModalService: ELearningClassEnrollmentInvoiceModalService, private toasterService: ToasterService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) {
      return;
    }
    if (changes.eLearningClassEnrollment && changes.eLearningClassEnrollment.currentValue) {
      const eLearningClassEnrollment = changes.eLearningClassEnrollment.currentValue;
      this.invoiceAlreadySent = eLearningClassEnrollment.invoiceStatus === 'sent';
    }
  }

  async onInvoiceSent(): Promise<void> {
    try {
      await this.eLearningClassEnrollmentInvoiceModalService.markInvoiceSent(this.eLearningClassEnrollment);
      this.invoiceAlreadySent = true;
      this.sent.emit();
    } catch (err) {}
  }

  copyCompanyDataToClipboard() {
    const { tuition: {invoiceData }} = this.eLearningClassEnrollment;
    const companyDataText = `${invoiceData.name}
${invoiceData.address.streetAndNr}
${invoiceData.address.postalCodeAndCity}
${invoiceData.address.country}
${invoiceData.vatNumber}`;
    this.copyToClipboard(companyDataText);
  }

  copyELearningClassEnrollmentDataToClipboard() {
    let eLearningClassEnrollmentDataText = '...';
    this.copyToClipboard(eLearningClassEnrollmentDataText);
  }

  private copyToClipboard(text) {
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.toasterService.pop('success', 'Tekst', 'Tekst is gekopieerd naar het clipboard...');
  }
}
