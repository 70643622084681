import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WorkshopStatusLabelComponent } from './workshop-status-label.component';

@NgModule({
  imports: [CommonModule],
  declarations: [WorkshopStatusLabelComponent],
  providers: [],
  entryComponents: [],
  exports: [WorkshopStatusLabelComponent]
})
export class LabelsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LabelsModule,
      providers: []
    };
  }
}
