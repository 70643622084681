import { Injectable } from '@angular/core';
import { ConfirmModalService } from 'app/pages/components/buttons/confirmmodal';
import { ToasterService } from 'angular2-toaster';
import { OrderApi } from 'app/shared/sdk';

import { of as observableOf } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class OrderInvoiceModalService {
  constructor(
    private confirmModalService: ConfirmModalService,
    private toasterService: ToasterService,
    private orderService: OrderApi
  ) {}

  async markInvoiceSent(order): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const { header, content } = this.getHeaderAndContent.call(order);
      this.confirmModalService.confirm(header, content, null, () => {
        let actuallyMarkInvoiceSent$ = this.actuallyMarkInvoiceSent(order.id).pipe(
          tap(() => {
            resolve();

            this.toasterService.pop(
              'success',
              'Bestelling is aangepast',
              'Er is genoteerd dat een factuur verstuurd is'
            );
          }),
          catchError((err) => {
            this.toasterService.pop(
              'error',
              'Het is niet gelukt om te registreren dat de factuur verstuurd is',
            );

            reject();
            return observableOf(err);
          })
        );
        return actuallyMarkInvoiceSent$; //This callback function needs to return the observable so the modal will know when everything is handled, and it may close the modal.
      });
    });
  }

  private actuallyMarkInvoiceSent(orderId) {
    return this.orderService.markInvoiceSent(orderId).flatMap(() => {
      //No need to return anything special...
      return observableOf(null);
    });
  }

  private getHeaderAndContent() {
    return {
      header: 'Factuur aan de klant als verstuurd markeren?',
      content: `
        <p>Weet je zeker dat je wilt aangeven dat je een factuur hebt verstuurd aan deze klant?</p>
        <p>Wat zal er gebeuren?</p>
        <ul>
          <li>Er wordt geen factuur gemaakt. Deze dient manueel gemaakt en gemaild te worden.</li>
          <li>Er wordt enkel aangegeven dat er een factuur is verstuurd aan de klant</li>
        </ul>`
    };
  }
}
