import { Component, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { MailPreview } from './mail-preview.interface';

@Component({
  selector: 'snr-preview-mail-modal',
  templateUrl: './preview-mail-modal.component.html',
  styleUrls: ['./preview-mail-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush //Counters error 'Expression has changed after it was checked'
})
export class PreviewMailModalComponent implements AfterViewInit {
  header: string;
  mailPreviews: MailPreview[];
  isBusy: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef //Counters error 'Expression has changed after it was checked'
  ) {}

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  onCloseButtonClicked(): void {
    this.activeModal.close();
  }

  convertHtmlToBlobUrlAndSanitize(html: string): SafeResourceUrl {
    const blob = new Blob([html], { type: 'text/html' });
    const blobUrl = URL.createObjectURL(blob);
    const sanitizedBlobUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
    return sanitizedBlobUrl;
  }
}
