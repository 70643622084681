import { Input, Component, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

const defaultPdfIconDataUri =
  'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDABgQEhUSDxgVExUaGRgcIzsmIyAgI0gzNis7VUtaWFRLUlFeaodzXmSAZVFSdqB3gIyQl5mXW3GmsqWTsIeUl5L/2wBDARkaGiMfI0UmJkWSYVJhkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpL/wAARCAA7ACoDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwb/xAAiEAACAgEDBAMAAAAAAAAAAAAAAQIRAxIhMSIyQVETobH/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8A6oAAACrmlJRW7/ALAAAAZ5W3G4u4+aAs8kE6clZnbxNxSvU9mzRRg40kqKQjevHLdLgDSKaVN2/ZJEE1Gm7fskDPL3LV2ERS+Xo4rf0atJqnwUxpwbjXT4YEVKDqCuL+i8I6VzbfLJAAAAAAAAAAAAf/2Q==';

@Component({
  selector: 'snr-single-file-panel',
  templateUrl: './single-file-panel.component.html',
  styleUrls: ['./single-file-panel.component.scss']
})
export class SingleFilePanelComponent implements OnChanges {
  @Input()
  fileUrl: string;
  @Output() fileUrlChange: EventEmitter<string> = new EventEmitter<string>();

  //duplicatedFileUrl is the one that is output in the UI. When it's not equal to fileUrl, then it means fileUrl was marked as deleted.
  duplicatedFileUrl: string;
  dataUriForUI: string;
  estimatedFileSizeInKB: number;

  fileDragInProgress: boolean = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.fileUrl) {
      this.duplicatedFileUrl = changes.fileUrl.currentValue;
      this.dataUriForUI = !changes.fileUrl.currentValue
        ? ''
        : !changes.fileUrl.currentValue.startsWith('data:image/')
        ? defaultPdfIconDataUri
        : changes.fileUrl.currentValue;
    }
  }

  onDragOverFile(event): void {
    this.fileDragInProgress = true;
    event.stopPropagation();
    event.preventDefault();
  }
  onDragLeaveFile($event): void {
    this.fileDragInProgress = false;
    event.stopPropagation();
    event.preventDefault();
  }

  onDropFile(event: DragEvent) {
    this.fileDragInProgress = false;
    event.preventDefault();
    this._addFileUrl(event.dataTransfer.files);
  }

  onFileChanged(event) {
    this._addFileUrl(event.target.files);
  }

  onMarkFileUrlAsDeleted(fileUrl) {
    this.fileUrl = '';
    this.fileUrlChange.emit(this.fileUrl);

    if (fileUrl.indexOf('data') === 0) {
      //A dataUri; which means it's a newly added file. We will really be removing it...
      this.duplicatedFileUrl = '';
      this.dataUriForUI = '';
    }
  }

  onUnmarkFileUrlAsDeleted(fileUrl) {
    this.fileUrl = fileUrl;
    this.fileUrlChange.emit(this.fileUrl);
  }

  _addFileUrl(files: FileList) {
    if (files.length === 0) {
      //console.log('No file selected!');
      return;
    }

    let file: File = files[0];
    const fileReader = new FileReader();
    fileReader.addEventListener(
      'load',
      () => {
        const dataUriFileUrl = <string>fileReader.result;
        this.fileUrl = dataUriFileUrl;
        this.fileUrlChange.emit(this.fileUrl);
        this.duplicatedFileUrl = dataUriFileUrl;
        this.dataUriForUI = !dataUriFileUrl.startsWith('data:image/') ? defaultPdfIconDataUri : dataUriFileUrl;

        this.estimatedFileSizeInKB = Math.round(((dataUriFileUrl.length - 22) * 0.75) / 1024);
      },
      false
    );
    fileReader.readAsDataURL(file);
  }
}
