import { Input, Component } from '@angular/core';
import { ToasterService } from '../../../../../node_modules/angular2-toaster';
import { WorkshopRegistrationApi } from '../../../shared/sdk';
import { of as observableOf } from 'rxjs';
import { ConfirmModalService } from './confirmmodal';

import { PreviewMailModalService } from '../preview-mail-modal/preview-mail-modal.service';
import { MailPreview } from '../preview-mail-modal/mail-preview.interface';

@Component({
  selector: 'snr-follow-up-on-proving-validity-workshop-registration-button',
  templateUrl: './follow-up-on-proving-validity-workshop-registration-button.component.html',
  styleUrls: ['./buttons.scss']
})
export class FollowUpOnProvingValidityWorkshopRegistrationButtonComponent {
  @Input()
  workshopRegistration: any;

  constructor(
    private confirmModalService: ConfirmModalService,
    //tslint:disable-next-line
    private toasterService: ToasterService,
    //tslint:disable-next-line
    private workshopRegistrationService: WorkshopRegistrationApi,
    //tslint:disable-next-line
    private previewMailModalService: PreviewMailModalService
  ) {}

  onSendFollowUpMail(): void {
    const { header, content, options } = getHeaderContentAndOptions.call(this);
    this.confirmModalService.confirm(header, content, options, () => {
      //The only option is a button, which is not returning a value. So we con't need to handle chosenOptions.
      return sendFollowUpMail.call(this);
    });

    function getHeaderContentAndOptions() {
      return {
        header: 'Opvolgmail versturen?',
        content: `
          <p>Weet je zeker dat je de deelnemer een opvolgmail wilt sturen met daarin information over de nood om haar inschrijving te bevestigen?</p>
          <p>Wat zal er gebeuren?</p>
          <ul>
            <li>De deelnemer zal een mail aankrijgen waarin staat dat de vorige mail waarschijnlijk in haar spam folder terecht is gekomen, en dat ze die daar moet zoeken zodat er in de toekomst geen mails van ons meer in de spam folder zullen verdwijnen.</li>
          </ul>`,
        options: [
          {
            name: 'checkEmails',
            label: 'Bekijk de mail die verstuurd zal worden',
            inputType: 'button',
            click: () => {
              this.workshopRegistrationService
                .getMailForFollowUpOnProvingValidity(this.workshopRegistration.id)
                .subscribe(mailHtml => {
                  const mailPreview: MailPreview = {
                    to: this.workshopRegistration.emailAddress,
                    html: mailHtml
                  };
                  this.previewMailModalService.showMailPreview(mailPreview);
                });
            }
          }
        ]
      };
    }

    function sendFollowUpMail() {
      return this.workshopRegistrationService
        .followUpOnProvingValidity(this.workshopRegistration.id)
        .flatMap(success => {
          if (success) {
            this.toasterService.pop('success', 'Mail', 'De opvolgmail is verstuurd...');
          }
          return observableOf(null);
        });
    }
  }
}
