/* tslint:disable */

declare var Object: any;
export interface ELearningClassInterface {
  "relatedWorkshopSubjectId": any;
  "language": string;
  "slug": string;
  "keywords"?: string;
  "price": number;
  "relatedOptionalELearningDigitalResourceIds"?: Array<any>;
  "relatedMandatoryELearningDigitalResourceIds"?: Array<any>;
  "relatedELearningDigitalResourceIds"?: Array<any>;
  "content": any;
  "faq": any;
  "id"?: any;
}

export class ELearningClass implements ELearningClassInterface {
  "relatedWorkshopSubjectId": any;
  "language": string;
  "slug": string;
  "keywords": string;
  "price": number;
  "relatedOptionalELearningDigitalResourceIds": Array<any>;
  "relatedMandatoryELearningDigitalResourceIds": Array<any>;
  "relatedELearningDigitalResourceIds": Array<any>;
  "content": any;
  "faq": any;
  "id": any;
  constructor(data?: ELearningClassInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ELearningClass`.
   */
  public static getModelName() {
    return "ELearningClass";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ELearningClass for dynamic purposes.
  **/
  public static factory(data: ELearningClassInterface): ELearningClass{
    return new ELearningClass(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ELearningClass',
      plural: 'ElearningClasses',
      path: 'ElearningClasses',
      idName: 'id',
      properties: {
        "relatedWorkshopSubjectId": {
          name: 'relatedWorkshopSubjectId',
          type: 'any'
        },
        "language": {
          name: 'language',
          type: 'string',
          default: 'nl'
        },
        "slug": {
          name: 'slug',
          type: 'string'
        },
        "keywords": {
          name: 'keywords',
          type: 'string'
        },
        "price": {
          name: 'price',
          type: 'number'
        },
        "relatedOptionalELearningDigitalResourceIds": {
          name: 'relatedOptionalELearningDigitalResourceIds',
          type: 'Array&lt;any&gt;'
        },
        "relatedMandatoryELearningDigitalResourceIds": {
          name: 'relatedMandatoryELearningDigitalResourceIds',
          type: 'Array&lt;any&gt;'
        },
        "relatedELearningDigitalResourceIds": {
          name: 'relatedELearningDigitalResourceIds',
          type: 'Array&lt;any&gt;'
        },
        "content": {
          name: 'content',
          type: 'any'
        },
        "faq": {
          name: 'faq',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
