/* tslint:disable */

declare var Object: any;
export interface ELearningLessonInterface {
  "language": string;
  "name": string;
  "keywords"?: string;
  "description": string;
  "externalVideoId": string;
  "videoDuration": number;
  "relatedELearningDigitalResourceIds"?: Array<any>;
  "relatedNeededELearningPhysicalResourceIds"?: Array<any>;
  "relatedOptionallyNeededLearningPhysicalResourceIds"?: Array<any>;
  "id"?: any;
}

export class ELearningLesson implements ELearningLessonInterface {
  "language": string;
  "name": string;
  "keywords": string;
  "description": string;
  "externalVideoId": string;
  "videoDuration": number;
  "relatedELearningDigitalResourceIds": Array<any>;
  "relatedNeededELearningPhysicalResourceIds": Array<any>;
  "relatedOptionallyNeededLearningPhysicalResourceIds": Array<any>;
  "id": any;
  constructor(data?: ELearningLessonInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ELearningLesson`.
   */
  public static getModelName() {
    return "ELearningLesson";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ELearningLesson for dynamic purposes.
  **/
  public static factory(data: ELearningLessonInterface): ELearningLesson{
    return new ELearningLesson(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ELearningLesson',
      plural: 'ELearningLessons',
      path: 'ELearningLessons',
      idName: 'id',
      properties: {
        "language": {
          name: 'language',
          type: 'string',
          default: 'nl'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "keywords": {
          name: 'keywords',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "externalVideoId": {
          name: 'externalVideoId',
          type: 'string'
        },
        "videoDuration": {
          name: 'videoDuration',
          type: 'number'
        },
        "relatedELearningDigitalResourceIds": {
          name: 'relatedELearningDigitalResourceIds',
          type: 'Array&lt;any&gt;'
        },
        "relatedNeededELearningPhysicalResourceIds": {
          name: 'relatedNeededELearningPhysicalResourceIds',
          type: 'Array&lt;any&gt;'
        },
        "relatedOptionallyNeededLearningPhysicalResourceIds": {
          name: 'relatedOptionallyNeededLearningPhysicalResourceIds',
          type: 'Array&lt;any&gt;'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
