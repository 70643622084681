import { Injectable } from '@angular/core';
import { NgbModal } from '../../../../../node_modules/@ng-bootstrap/ng-bootstrap';
import { PreviewMailModalComponent } from './preview-mail-modal.component';

import { MailPreview } from './mail-preview.interface';

@Injectable()
export class PreviewMailModalService {
  constructor(private modalService: NgbModal) {}

  showMailPreview(mailPreview: MailPreview): void {
    this._showMailPreviews([mailPreview]);
  }

  showMailPreviews(mailPreviews: MailPreview[]): void {
    this._showMailPreviews(mailPreviews);
  }

  private _showMailPreviews(mailPreviews: MailPreview[]) {
    const activeModal = this.modalService.open(PreviewMailModalComponent, {
      size: 'lg',
      container: 'nb-layout'
    });
    Object.assign(activeModal.componentInstance, {
      mailPreviews: mailPreviews
    });
  }
}
