/* tslint:disable */

declare var Object: any;
export interface LogInterface {
  "timestamp": Date;
  "humanReadableMessage"?: string;
  "id"?: any;
  "relatedWorkshopId"?: any;
  "relatedWorkshopRegistrationId"?: any;
  "relatedWorkshopRegistrationBackupId"?: any;
}

export class Log implements LogInterface {
  "timestamp": Date;
  "humanReadableMessage": string;
  "id": any;
  "relatedWorkshopId": any;
  "relatedWorkshopRegistrationId": any;
  "relatedWorkshopRegistrationBackupId": any;
  constructor(data?: LogInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Log`.
   */
  public static getModelName() {
    return "Log";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Log for dynamic purposes.
  **/
  public static factory(data: LogInterface): Log{
    return new Log(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Log',
      plural: 'Logs',
      path: 'Logs',
      idName: 'id',
      properties: {
        "timestamp": {
          name: 'timestamp',
          type: 'Date',
          default: new Date(0)
        },
        "humanReadableMessage": {
          name: 'humanReadableMessage',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "relatedWorkshopId": {
          name: 'relatedWorkshopId',
          type: 'any'
        },
        "relatedWorkshopRegistrationId": {
          name: 'relatedWorkshopRegistrationId',
          type: 'any'
        },
        "relatedWorkshopRegistrationBackupId": {
          name: 'relatedWorkshopRegistrationBackupId',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
