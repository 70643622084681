/* tslint:disable */
import { Injectable } from '@angular/core';
import { User } from '../../models/User';
import { Order } from '../../models/Order';
import { VAT } from '../../models/VAT';
import { Inquiry } from '../../models/Inquiry';
import { Product } from '../../models/Product';
import { Coupon } from '../../models/Coupon';
import { Story } from '../../models/Story';
import { PointOfSale } from '../../models/PointOfSale';
import { Workshop } from '../../models/Workshop';
import { WorkshopSubject } from '../../models/WorkshopSubject';
import { WorkshopRegistration } from '../../models/WorkshopRegistration';
import { WorkshopRegistrationBackup } from '../../models/WorkshopRegistrationBackup';
import { Log } from '../../models/Log';
import { Moodboard } from '../../models/Moodboard';
import { NewsletterSubscription } from '../../models/NewsletterSubscription';
import { Customer } from '../../models/Customer';
import { Book } from '../../models/Book';
import { ELearningDigitalResource } from '../../models/ELearningDigitalResource';
import { ELearningPhysicalResource } from '../../models/ELearningPhysicalResource';
import { ELearningLesson } from '../../models/ELearningLesson';
import { ELearningClass } from '../../models/ELearningClass';
import { ELearningClassContent } from '../../models/ELearningClassContent';
import { ELearningClassSummary } from '../../models/ELearningClassSummary';
import { ELearningClassEnrollment } from '../../models/ELearningClassEnrollment';
import { WorkshopPayment } from '../../models/WorkshopPayment';
import { ELearningClassEnrollmentLimit } from '../../models/ELearningClassEnrollmentLimit';
import { ELearningClassEnrollmentBackup } from '../../models/ELearningClassEnrollmentBackup';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    User: User,
    Order: Order,
    VAT: VAT,
    Inquiry: Inquiry,
    Product: Product,
    Coupon: Coupon,
    Story: Story,
    PointOfSale: PointOfSale,
    Workshop: Workshop,
    WorkshopSubject: WorkshopSubject,
    WorkshopRegistration: WorkshopRegistration,
    WorkshopRegistrationBackup: WorkshopRegistrationBackup,
    Log: Log,
    Moodboard: Moodboard,
    NewsletterSubscription: NewsletterSubscription,
    Customer: Customer,
    Book: Book,
    ELearningDigitalResource: ELearningDigitalResource,
    ELearningPhysicalResource: ELearningPhysicalResource,
    ELearningLesson: ELearningLesson,
    ELearningClass: ELearningClass,
    ELearningClassContent: ELearningClassContent,
    ELearningClassSummary: ELearningClassSummary,
    ELearningClassEnrollment: ELearningClassEnrollment,
    WorkshopPayment: WorkshopPayment,
    ELearningClassEnrollmentLimit: ELearningClassEnrollmentLimit,
    ELearningClassEnrollmentBackup: ELearningClassEnrollmentBackup,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
