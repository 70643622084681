import { Input, Component, Output, EventEmitter } from '@angular/core';
import { ToasterService } from '../../../../../node_modules/angular2-toaster';
import { WorkshopRegistrationApi } from '../../../shared/sdk';
import { of as observableOf } from 'rxjs';
import { ConfirmModalService } from './confirmmodal';

import { PreviewMailModalService } from '../preview-mail-modal/preview-mail-modal.service';
import { MailPreview } from '../preview-mail-modal/mail-preview.interface';

@Component({
  selector: 'snr-workshop-registration-send-last-reminder-button',
  templateUrl:
    './workshop-registration-send-last-reminder-button.component.html',
  styleUrls: ['./buttons.scss']
})
export class WorkshopRegistrationSendLastReminderButtonComponent {
  @Input()
  workshopRegistration: any;
  @Output()
  sent: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private confirmModalService: ConfirmModalService,
    //tslint:disable-next-line
    private toasterService: ToasterService,
    //tslint:disable-next-line
    private workshopRegistrationService: WorkshopRegistrationApi,
    //tslint:disable-next-line
    private previewMailModalService: PreviewMailModalService
  ) {}

  onSendLastReminder(): void {
    const { header, content, options } = getHeaderContentAndOptions.call(this);
    this.confirmModalService.confirm(header, content, options, () => {
      return onSendLastReminder.call(this);
    });

    function getHeaderContentAndOptions() {
      return {
        header: 'Laatste herinnering over de start van de workshop mailen?',
        content: `
          <p>Weet je zeker dat je deze mail met info wilt mailen naar de cursist?</p>
          <p>Wat zal er gebeuren?</p>
          <ul>
            <li>De cursist krijgt een mail toegestuurd met daarin alle info over de workshop (wanneer, hoe bereiken, waar parkeren,...). Dit gebeurt best een paar dagen voor de start.</li>
          </ul>`,
        options: [
          {
            name: 'checkEmails',
            label: 'Bekijk de mail die verstuurd zal worden',
            inputType: 'button',
            click: () => {
              this.workshopRegistrationService
                .getMailsForSendLastReminder(this.workshopRegistration.id)
                .subscribe(mails => {
                  const mailPreviews: MailPreview[] = mails.map(mail => {
                    return {
                      to: mail.to,
                      html: mail.html
                    };
                  });
                  this.previewMailModalService.showMailPreviews(mailPreviews);
                });
            }
          }
        ]
      };
    }

    function onSendLastReminder() {
      return this.workshopRegistrationService
        .sendLastReminder(this.workshopRegistration.id)
        .flatMap(() => {
          this.sent.emit();
          this.toasterService.pop(
            'success',
            'Mail is verstuurd',
            'De mail met de info is onderweg naar de cursist...'
          );
          return observableOf(null);
        });
    }
  }
}
