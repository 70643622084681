import { Input, Component, Output, EventEmitter } from '@angular/core';
import { ToasterService } from '../../../../../node_modules/angular2-toaster';
import { WorkshopRegistrationApi } from '../../../shared/sdk';
import { of as observableOf } from 'rxjs';
import { ConfirmModalService } from './confirmmodal';

import { PreviewMailModalService } from '../preview-mail-modal/preview-mail-modal.service';
import { MailPreview } from '../preview-mail-modal/mail-preview.interface';

@Component({
  selector: 'snr-cancel-workshop-registration-button',
  templateUrl: './cancel-workshop-registration-button.component.html',
  styleUrls: ['./buttons.scss']
})
export class CancelWorkshopRegistrationButtonComponent {
  @Input()
  workshopRegistration: any;
  @Output()
  cancelled: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  revoked: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private confirmModalService: ConfirmModalService,
    //tslint:disable-next-line
    private toasterService: ToasterService,
    //tslint:disable-next-line
    private workshopRegistrationService: WorkshopRegistrationApi,
    //tslint:disable-next-line
    private previewMailModalService: PreviewMailModalService
  ) {}

  onChangeStatusToCancelled(): void {
    const { header, content, options } = getHeaderContentAndOptions.call(this);
    this.confirmModalService.confirm(header, content, options, chosenOptions => {
      return changeStatusToCancelled.call(this, chosenOptions);
    });

    function getHeaderContentAndOptions() {
      return {
        header: 'Inschrijving zelf annuleren?',
        content: `
          <p>Weet je zeker dat je status wilt veranderen naar 'geannuleerd'?</p>
          <p>Wat zal er gebeuren?</p>
          <ul>
            <li>Aangezien wij de inschrijving annuleren - en niet de deelnemer zelf - zullen we het volledig bedrag dat we van de deelnemer hebben ontvangen, terugstorten</li>
            <li>Indien je wenst, zal de deelnemer een mail aankrijgen waarin staat dat de inschrijving geannuleerd is</li>
          </ul>`,
        options: [
          {
            name: 'doSendEmails',
            label: 'Wil je de deelnemer hiervan op de hoogte brengen door haar een mail te sturen?',
            inputType: 'boolean',
            value: true
          },
          {
            name: 'checkEmails',
            label: 'Bekijk de mail die verstuurd zal worden',
            inputType: 'button',
            isDisabled: chosenOptions => {
              return !chosenOptions.doSendEmails;
            },
            click: (/*chosenOptions*/) => {
              this.workshopRegistrationService
                .getMailForCancel(this.workshopRegistration.id)
                .subscribe(({ body, to }) => {
                  const mailPreview: MailPreview = {
                    html: body,
                    to: to
                  };
                  this.previewMailModalService.showMailPreview(mailPreview);
                });
            }
          }
        ]
      };
    }

    function changeStatusToCancelled(chosenOptions) {
      return this.workshopRegistrationService
        .cancel(this.workshopRegistration.id, chosenOptions.doSendEmails)
        .flatMap(({ success, amountDue }) => {
          if (success) {
            this.workshopRegistration.status = 'cancelled';
            this.workshopRegistration.amountDue = amountDue;
            this.cancelled.emit();
            this.toasterService.pop('success', 'Inschrijving', 'Inschrijving is geannuleerd...');
            if (chosenOptions.doSendEmails) {
              this.toasterService.pop(
                'success',
                'Workshop',
                'De deelnemer is van de annulatie op de hoogte gebracht door een mail...'
              );
            }
          }
          return observableOf(null);
        });
    }
  }

  onChangeStatusToRevoked(): void {
    const { header, content, options } = getHeaderContentAndOptions.call(this);
    this.confirmModalService.confirm(header, content, options, chosenOptions => {
      return changeStatusToRevoked.call(this, chosenOptions);
    });

    function getHeaderContentAndOptions() {
      let conditionalContents = [];
      let applicableOptions = [];
      if (
        this.workshopRegistration.status === 'pendingRegistration' ||
        this.workshopRegistration.status === 'registered'
      ) {
        //If the workshopRegistration's status is not even 'participating', then that means the workshop's status is only 'planned'
        //  yet, and so there's no point in mentioning the amount paid etc...
        conditionalContents.push(
          `<li>Aangezien de inschrijving van de deelnemer nog niet eens vastgelegd was, heeft deze inschrijving geen gevolgen qua betaling/terugbetaling.</li>`
        );
      } else {
        //workshopRegistration.status === 'participating'
        const amountPaid = (this.workshopRegistration.paymentLog || []).reduce(
          (memo, log) =>
            memo + (log.status === 'paymentReceived' ? log.amount : log.status === 'refundSent' ? -log.amount : 0),
          0
        );
        if (amountPaid > 0) {
          if (this.workshopRegistration.amountDue === 0) {
            conditionalContents.push(
              `<li>De deelnemer heeft het volledige inschrijvingsbedrag van ${amountPaid} euro betaald</li>`
            );
          } else if (this.workshopRegistration.amountDue > 0) {
            conditionalContents.push(
              `<li>De deelnemer heeft ${amountPaid} euro van het inschrijvingsbedrag betaald en moest nog ${this.workshopRegistration.amountDue} euro betalen.</li>`
            );
          } else if (this.workshopRegistration.amountDue < 0) {
            conditionalContents.push(
              `<li>De deelnemer heeft ${amountPaid} euro betaald en moest nog ${-this.workshopRegistration
                .amountDue} euro terugkrijgen (waarschijnlijk omdat ze recent haar inschrijving nog had laten wijzigen).</li>`
            );
          }
          conditionalContents.push(
            `<li>Je kan ervoor kiezen om de annulatie-termijnen toe te passen (100% - 5 euro teruggave indien meer dan 14 dagen voor de start; 50% - 5 euro teruggave indien tussen 14 en 7 dagen voor de start en 0% teruggave indien minder dan 7 dagen voor de start - tenzij ze zelf iemand kunnen aanleveren als vervanging).</li>`
          );
          applicableOptions.push({
            name: 'doApplyCancellationTerms',
            label:
              'Wil je de annulatie-termijn doen gelden bij deze annulatie? Dit zal invloed hebben op de berekening van het terug te betalen bedrag, en de annulatie-termijnen zullen vermeld worden in de mail aan de deelnemer. Als je het niet doet gelden, zullen we gewoon 100% - 5 euro administratiekosten terugbetalen, ongeacht de termijn.',
            inputType: 'boolean',
            value: true
          });
        } else if (amountPaid === 0) {
          //The participant hadn't paid anything yet. Or maybe she did pay something, but she already got refunded (seems an unlikely situation)
          conditionalContents.push(
            `<li>De deelnemer heeft nog niets betaald.</li>` //Not exactly a 100% correct text in case everything she paid got refunded already, but that's ok. The net amount we received was 0 euro
          );
          conditionalContents.push(
            `<li>Normaal gezien zijn er annulatie-termijnen na te leven (meer dan 14 dagen voor de start; tussen 14 en 7 dagen voor de start en minder dan 7 dagen voor de start), maar aangezien de deelnemer nog niets betaald heeft, is het het makkelijkst om niet achter het geld te gaan.</li>`
          );
        }
      }

      applicableOptions.push({
        name: 'checkEmails',
        label: 'Bekijk de mail die verstuurd zal worden',
        inputType: 'button',
        // isDisabled: chosenOptions => {
        // },
        click: chosenOptions => {
          const doOutputCancellationTerms = chosenOptions.doApplyCancellationTerms || false;
          this.workshopRegistrationService
            .getMailsForRevokeByAdmin(this.workshopRegistration.id, doOutputCancellationTerms)
            .subscribe(mails => {
              const mailPreviews: MailPreview[] = mails.map(mail => {
                return {
                  to: mail.to,
                  html: mail.html
                };
              });
              this.previewMailModalService.showMailPreviews(mailPreviews);
            });
        }
      });

      const actualContent = `
      <p>Weet je zeker dat je status wilt veranderen naar 'ingetrokken/zelf geannuleerd'?</p>
      <p>Wat zal er gebeuren?</p>
      <ul>
        <li>De deelnemer zal een mail aankrijgen waarin staat dat de inschrijving ingetrokken is (omdat zij het gevraagd heeft)</li>
        <li>Wij krijgen een mailtje aan met de melding dat de deelnemer haar inschrijving ingetrokken heeft</li>
        ${conditionalContents.join('\r\n')}
      </ul>`;
      return {
        header: 'Wilt de deelnemer de inschrijving intrekken?',
        content: actualContent,
        options: applicableOptions
      };
    }

    function changeStatusToRevoked(chosenOptions) {
      return this.workshopRegistrationService
        .revokeByAdmin(this.workshopRegistration.id, chosenOptions.doApplyCancellationTerms || false)
        .flatMap(({ success, amountDue }) => {
          if (success) {
            this.workshopRegistration.status = 'revoked';
            this.workshopRegistration.amountDue = amountDue;
            this.revoked.emit();

            this.toasterService.pop('success', 'Inschrijving', 'Inschrijving is geannuleerd...');
          }
          return observableOf(null);
        });
    }
  }
}
