import { Input, Component, Output, EventEmitter } from '@angular/core';
import {
  WorkshopRegistrationPaymentModalService,
  WorkshopRegistrationPayment
} from '../../../services/workshop/workshopregistrationpaymentmodal';

@Component({
  selector: 'snr-workshop-registration-mark-payment-button',
  templateUrl: './workshop-registration-mark-payment-button.component.html',
  styleUrls: ['./buttons.scss']
})
export class WorkshopRegistrationMarkPaymentButtonComponent {
  @Input()
  workshopRegistration: any;
  @Input()
  workshop: any;
  @Input()
  workshopSubject: any;
  @Output()
  paid: EventEmitter<any> = new EventEmitter<any>();

  constructor(private workshopRegistrationPaymentModalService: WorkshopRegistrationPaymentModalService) {}

  async onMarkPayment(): Promise<void> {
    const payment: WorkshopRegistrationPayment = await this.workshopRegistrationPaymentModalService
      .markPayment(this.workshopRegistration, this.workshop, this.workshopSubject)
      .catch(err => {
        return null;
      });
    if (payment) {
      this.paid.emit(payment);
    }
  }
}
