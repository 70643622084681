/* tslint:disable */
import {
  Log
} from '../index';

declare var Object: any;
export interface WorkshopRegistrationInterface {
  "relatedWorkshopId": any;
  "relatedWorkshopSubjectId": any;
  "firstName": string;
  "lastName": string;
  "additionalPersons"?: any;
  "numberOfParticipants": number;
  "emailAddress": string;
  "hashedEmailAddress": string;
  "phoneNumber": string;
  "address": any;
  "company"?: any;
  "joinLunch"?: number;
  "orderStartersKit"?: number;
  "orderVaria1"?: number;
  "orderVaria2"?: number;
  "orderVaria3"?: number;
  "signUpForSupplementaryLessons"?: boolean;
  "information"?: string;
  "timestamp": Date;
  "language": string;
  "status": string;
  "paymentLog"?: any;
  "amountDue"?: number;
  "bankPaymentCode"?: string;
  "id"?: any;
  Logs?: Log[];
}

export class WorkshopRegistration implements WorkshopRegistrationInterface {
  "relatedWorkshopId": any;
  "relatedWorkshopSubjectId": any;
  "firstName": string;
  "lastName": string;
  "additionalPersons": any;
  "numberOfParticipants": number;
  "emailAddress": string;
  "hashedEmailAddress": string;
  "phoneNumber": string;
  "address": any;
  "company": any;
  "joinLunch": number;
  "orderStartersKit": number;
  "orderVaria1": number;
  "orderVaria2": number;
  "orderVaria3": number;
  "signUpForSupplementaryLessons": boolean;
  "information": string;
  "timestamp": Date;
  "language": string;
  "status": string;
  "paymentLog": any;
  "amountDue": number;
  "bankPaymentCode": string;
  "id": any;
  Logs: Log[];
  constructor(data?: WorkshopRegistrationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `WorkshopRegistration`.
   */
  public static getModelName() {
    return "WorkshopRegistration";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of WorkshopRegistration for dynamic purposes.
  **/
  public static factory(data: WorkshopRegistrationInterface): WorkshopRegistration{
    return new WorkshopRegistration(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'WorkshopRegistration',
      plural: 'WorkshopRegistrations',
      path: 'WorkshopRegistrations',
      idName: 'id',
      properties: {
        "relatedWorkshopId": {
          name: 'relatedWorkshopId',
          type: 'any'
        },
        "relatedWorkshopSubjectId": {
          name: 'relatedWorkshopSubjectId',
          type: 'any'
        },
        "firstName": {
          name: 'firstName',
          type: 'string'
        },
        "lastName": {
          name: 'lastName',
          type: 'string'
        },
        "additionalPersons": {
          name: 'additionalPersons',
          type: 'any'
        },
        "numberOfParticipants": {
          name: 'numberOfParticipants',
          type: 'number'
        },
        "emailAddress": {
          name: 'emailAddress',
          type: 'string'
        },
        "hashedEmailAddress": {
          name: 'hashedEmailAddress',
          type: 'string'
        },
        "phoneNumber": {
          name: 'phoneNumber',
          type: 'string'
        },
        "address": {
          name: 'address',
          type: 'any'
        },
        "company": {
          name: 'company',
          type: 'any'
        },
        "joinLunch": {
          name: 'joinLunch',
          type: 'number'
        },
        "orderStartersKit": {
          name: 'orderStartersKit',
          type: 'number'
        },
        "orderVaria1": {
          name: 'orderVaria1',
          type: 'number'
        },
        "orderVaria2": {
          name: 'orderVaria2',
          type: 'number'
        },
        "orderVaria3": {
          name: 'orderVaria3',
          type: 'number'
        },
        "signUpForSupplementaryLessons": {
          name: 'signUpForSupplementaryLessons',
          type: 'boolean'
        },
        "information": {
          name: 'information',
          type: 'string'
        },
        "timestamp": {
          name: 'timestamp',
          type: 'Date',
          default: new Date(0)
        },
        "language": {
          name: 'language',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "paymentLog": {
          name: 'paymentLog',
          type: 'any'
        },
        "amountDue": {
          name: 'amountDue',
          type: 'number'
        },
        "bankPaymentCode": {
          name: 'bankPaymentCode',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        Logs: {
          name: 'Logs',
          type: 'Log[]',
          model: 'Log',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'relatedWorkshopRegistrationId'
        },
      }
    }
  }
}
