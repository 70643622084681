import { Input, Component } from '@angular/core';

@Component({
  selector: 'snr-workshop-status-label',
  templateUrl: './workshop-status-label.component.html',
  styleUrls: ['./workshop-status-label.component.scss']
})
export class WorkshopStatusLabelComponent {
  @Input()
  workshop: any;

  constructor() {}
}
