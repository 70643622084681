import { Input, Component, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ToasterService } from '../../../../../node_modules/angular2-toaster';

import * as moment from 'moment';
import { WorkshopRegistrationInvoiceModalService } from '../../../services/workshop/workshopregistrationinvoicemodal';

//NOTE: for now, we don't actually send the invoice - which we need to make manually - via this UI. Instead, we just mark the workshopRegistration so we can see
//  for which ones we've already sent the invoice.

@Component({
  selector: 'snr-workshop-registration-invoice-sent-button',
  templateUrl: './workshop-registration-invoice-sent-button.component.html',
  styleUrls: ['./buttons.scss']
})
export class WorkshopRegistrationInvoiceSentButtonComponent implements OnChanges {
  @Input()
  workshopRegistration: any;
  @Input()
  workshop: any;
  @Input()
  workshopSubject: any;
  @Output()
  sent: EventEmitter<void> = new EventEmitter<void>();

  invoiceAlreadySent: boolean = false;

  constructor(
    private toasterService: ToasterService,
    private workshopRegistrationInvoiceModalService: WorkshopRegistrationInvoiceModalService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) {
      return;
    }
    if (changes.workshopRegistration && changes.workshopRegistration.currentValue) {
      const wr = changes.workshopRegistration.currentValue;
      this.invoiceAlreadySent = wr.paymentLog && wr.paymentLog.some(pl => pl.status === 'invoiceSent');
    }
  }

  async onInvoiceSent(): Promise<void> {
    try {
      await this.workshopRegistrationInvoiceModalService.markInvoiceSent(this.workshopRegistration);
      this.invoiceAlreadySent = true;
      this.sent.emit();
    } catch (err) {}
  }

  copyCompanyDataToClipboard() {
    const companyDataText = `${this.workshopRegistration.company.name}
${this.workshopRegistration.company.address.streetAndNr}
${this.workshopRegistration.company.address.postalCodeAndCity}
${this.workshopRegistration.company.address.country}
${this.workshopRegistration.company.vatNumber}`;
    this.copyToClipboard(companyDataText);
  }

  copyWorkshopDataToClipboard() {
    const clipboardLines = [
      this._createWorkshopLine(this.workshopSubject, this.workshop, this.workshopRegistration),
      ...this._createSupplementaryLessonsLines(this.workshop, this.workshopRegistration),
      ...this._createLunchLines(this.workshop, this.workshopRegistration),
      ...this._createStartersKitLines(this.workshop, this.workshopRegistration),
      ...this._createVaria1Lines(this.workshop, this.workshopRegistration),
      ...this._createVaria2Lines(this.workshop, this.workshopRegistration),
      ...this._createVaria3Lines(this.workshop, this.workshopRegistration),
    ];
    const workshopDataText = clipboardLines.join('\r\n');

    this.copyToClipboard(workshopDataText);
  }

  copyEmailTextToClipboard() {
    const firstName = this.workshopRegistration.firstName;
    const workshopName = this.workshopSubject.nl.name;

    const includeSupplementaryLessons = !!this.workshopRegistration.signUpForSupplementaryLessons;
    const dates = this.workshop.dates.filter(date => !date.isOptional || includeSupplementaryLessons);
    const happensOrStarts = dates.length === 1 ? 'doorgaat' : 'start';
    const firstDay = dates[0];
    const firstDayMoment = moment(firstDay.start);
    firstDayMoment.locale('nl');
    const dateFirstDay = firstDayMoment.format('LL'); //E.g. 20 februari 2020

    const clipboardLines = [
      `Hallo ${firstName},`,
      ``,
      `Je had aangegeven dat je een factuur wou ontvangen voor jouw inschrijving in de ${workshopName} die ${happensOrStarts} op ${dateFirstDay}.`,
      ``,
      `Je kan de factuur als bijlage bij deze mail vinden.`,
      ``,
      `mvg,`,
      `   Daniel (van de boekhouding van Snow & Rose)`
    ];
    const workshopDataText = clipboardLines.join('\r\n');

    this.copyToClipboard(workshopDataText);
  }

  private _createWorkshopLine(workshopSubject, workshop, workshopRegistration) {
    const participantCount = workshopRegistration.numberOfParticipants;
    let name;
    if (workshop.dates.length === 1) {
      const startMoment = moment(workshop.dates[0].start);
      startMoment.locale('nl');
      name = `${workshopSubject.nl.name} (${startMoment.format('LL')})`;
    } else {
      const startMoment = moment(workshop.dates[0].start);
      startMoment.locale('nl');
      const additionalDatesCount = workshop.dates.length - 1;
      name = `${workshopSubject.nl.name} (${startMoment.format('LL')} + ${additionalDatesCount} andere data)`;
    }
    const workshopPriceInclVAT = this._ensureNumberFormat(workshop.price);
    const workshopPriceExclVAT = this._ensureNumberFormat(workshop.price / 1.21, 4);
    const vatRate = '0,21';
    return `${participantCount}\t${name}\t${workshopPriceInclVAT}\t${workshopPriceExclVAT}\t${vatRate}`;
  }

  private _createSupplementaryLessonsLines(workshop, workshopRegistration) {
    if (
      !workshopRegistration.signUpForSupplementaryLessons ||
      !workshop.providedExtras ||
      !workshop.providedExtras.supplementaryLessons
    ) {
      return [];
    }
    const supplementaryLessonsCount = 1;
    const description = workshop.providedExtras.supplementaryLessons.description || 'Bijkomende lessen';
    const priceInclVAT = this._ensureNumberFormat(workshop.providedExtras.supplementaryLessons.price);
    const priceExclVAT = this._ensureNumberFormat(workshop.providedExtras.supplementaryLessons.price / 1.21, 4);
    const vatRate = '0,21';
    return [`${supplementaryLessonsCount}\t${description}\t${priceInclVAT}\t${priceExclVAT}\t${vatRate}`];
  }

  private _createLunchLines(workshop, workshopRegistration) {
    if (!workshopRegistration.joinLunch || !workshop.providedExtras || !workshop.providedExtras.lunch) {
      return [];
    }
    const numberOfPeopleLunching = workshopRegistration.joinLunch;
    const includeSupplementaryLessons =
      workshopRegistration.signUpForSupplementaryLessons &&
      workshop.providedExtras &&
      workshop.providedExtras.supplementaryLessons;
    const numberOfDays = workshop.dates.filter(date => !date.isOptional || includeSupplementaryLessons).length;

    const lunchCount = numberOfPeopleLunching * numberOfDays;
    const description = 'Lunch';
    const priceInclVAT = this._ensureNumberFormat(workshop.providedExtras.lunch.price);
    const priceExclVAT = this._ensureNumberFormat(workshop.providedExtras.lunch.price / 1.21, 4);
    const vatRate = '0,21';
    return [`${lunchCount}\t${description}\t${priceInclVAT}\t${priceExclVAT}\t${vatRate}`];
  }

  private _createStartersKitLines(workshop, workshopRegistration) {
    if (!workshopRegistration.orderStartersKit || !workshop.providedExtras || !workshop.providedExtras.startersKit) {
      return [];
    }
    const startersKitCount = workshopRegistration.orderStartersKit;
    const description = workshop.providedExtras.startersKit.description;
    const priceInclVAT = this._ensureNumberFormat(workshop.providedExtras.startersKit.price);
    const priceExclVAT = this._ensureNumberFormat(workshop.providedExtras.startersKit.price / 1.21, 4);
    const vatRate = '0,21';
    return [`${startersKitCount}\t${description}\t${priceInclVAT}\t${priceExclVAT}\t${vatRate}`];
  }

  private _createVaria1Lines(workshop, workshopRegistration) {
    if (!workshopRegistration.orderVaria1 || !workshop.providedExtras || !workshop.providedExtras.varia1) {
      return [];
    }
    const varia1Count = workshopRegistration.orderVaria1;
    const description = workshop.providedExtras.varia1.description;
    const priceInclVAT = this._ensureNumberFormat(workshop.providedExtras.varia1.price);
    const priceExclVAT = this._ensureNumberFormat(workshop.providedExtras.varia1.price / 1.21, 4);
    const vatRate = '0,21';
    return [`${varia1Count}\t${description}\t${priceInclVAT}\t${priceExclVAT}\t${vatRate}`];
  }
  private _createVaria2Lines(workshop, workshopRegistration) {
    if (!workshopRegistration.orderVaria2 || !workshop.providedExtras || !workshop.providedExtras.varia2) {
      return [];
    }
    const varia2Count = workshopRegistration.orderVaria2;
    const description = workshop.providedExtras.varia2.description;
    const priceInclVAT = this._ensureNumberFormat(workshop.providedExtras.varia2.price);
    const priceExclVAT = this._ensureNumberFormat(workshop.providedExtras.varia2.price / 1.21, 4);
    const vatRate = '0,21';
    return [`${varia2Count}\t${description}\t${priceInclVAT}\t${priceExclVAT}\t${vatRate}`];
  }
  private _createVaria3Lines(workshop, workshopRegistration) {
    if (!workshopRegistration.orderVaria3 || !workshop.providedExtras || !workshop.providedExtras.varia3) {
      return [];
    }
    const varia3Count = workshopRegistration.orderVaria3;
    const description = workshop.providedExtras.varia3.description;
    const priceInclVAT = this._ensureNumberFormat(workshop.providedExtras.varia3.price);
    const priceExclVAT = this._ensureNumberFormat(workshop.providedExtras.varia3.price / 1.21, 4);
    const vatRate = '0,21';
    return [`${varia3Count}\t${description}\t${priceInclVAT}\t${priceExclVAT}\t${vatRate}`];
  }

  private _ensureNumberFormat(number, decimals?) {
    const factor = Math.pow(10, decimals || 0);
    return (Math.round(number * factor) / factor).toString().replace('.', ',');
  }

  private copyToClipboard(text) {
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.toasterService.pop('success', 'Tekst', 'Tekst is gekopieerd naar het clipboard...');
  }
}
