import { Input, Component, Output, EventEmitter } from '@angular/core';
import { ToasterService } from '../../../../../node_modules/angular2-toaster';
import { WorkshopRegistrationApi } from '../../../shared/sdk';
import { of as observableOf } from 'rxjs';
import { ConfirmModalService } from './confirmmodal';

import { sprintf } from 'sprintf-js';

import { PreviewMailModalService } from '../preview-mail-modal/preview-mail-modal.service';
import { MailPreview } from '../preview-mail-modal/mail-preview.interface';

@Component({
  selector: 'snr-workshop-registration-mark-refund-button',
  templateUrl: './workshop-registration-mark-refund-button.component.html',
  styleUrls: ['./buttons.scss']
})
export class WorkshopRegistrationMarkRefundButtonComponent {
  @Input()
  workshopRegistration: any;
  @Output()
  refunded: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private confirmModalService: ConfirmModalService,
    //tslint:disable-next-line
    private toasterService: ToasterService,
    //tslint:disable-next-line
    private workshopRegistrationService: WorkshopRegistrationApi,
    //tslint:disable-next-line
    private previewMailModalService: PreviewMailModalService
  ) {}

  onMarkRefund(): void {
    const { header, content, options } = getHeaderContentAndOptions.call(this);
    this.confirmModalService.confirm(header, content, options, chosenOptions => {
      return markRefund.call(this, chosenOptions);
    });

    function getHeaderContentAndOptions() {
      return {
        header: 'Een terugbetaling registreren?',
        content: sprintf(
          `
          <p>Doe dit enkel als we de cursist %1$d euro terugbetaald hebben.</p>
          <p>Wat zal er gebeuren?</p>
          <ul>
            <li>Er wordt genoteerd dat we %1$d euro aan de cursist hebben terugbetaald.</li>
            <li>Optioneel wordt er een mail verstuurd aan de cursist om te zeggen dat we het bedrag teruggestort hebben</li>
          </ul>`,
          -this.workshopRegistration.amountDue
        ),
        options: [
          {
            name: 'doSendEmails',
            label: 'Wil je de deelnemer hiervan op de hoogte brengen door haar een mail te sturen?',
            inputType: 'boolean',
            value: true
          },
          {
            name: 'checkEmails',
            label: 'Bekijk de mail die verstuurd zal worden',
            inputType: 'button',
            isDisabled: chosenOptions => {
              return !chosenOptions.doSendEmails;
            },
            click: (/*chosenOptions*/) => {
              this.workshopRegistrationService
                .getMailForRefundProcessed(this.workshopRegistration.id)
                .subscribe(({ body, to }) => {
                  const mailPreview: MailPreview = {
                    to: to,
                    html: body
                  };
                  this.previewMailModalService.showMailPreview(mailPreview);
                });
            }
          }
        ]
      };
    }

    function markRefund(chosenOptions) {
      const amount = -this.workshopRegistration.amountDue;
      return this.workshopRegistrationService
        .markRefund(
          this.workshopRegistration.id,
          amount, //we need to pass a positive number, and .amountDue was negative.
          chosenOptions.doSendEmails
        )
        .flatMap(success => {
          this.refunded.emit(amount); //Here too, we need to pass a positive number.

          this.toasterService.pop(
            'success',
            'Terugbetaling is geregistreerd',
            'Er is geregistreerd dat we de cursist ' + amount + ' euro hebben terugbetaald'
          );
          return observableOf(null);
        });
    }
  }
}
