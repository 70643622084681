import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">Snow &amp; Rose</span>
    <div class="socials">
      <a href="https://www.facebook.com/TheWorldOfSnowAndRose" target="_blank" class="ion ion-social-facebook"></a>
      <a href="https://www.instagram.com" target="_blank" class="ion ion-social-instagram"></a>
    </div>
  `
})
export class FooterComponent {}
