import { Input, Component, Output, EventEmitter } from '@angular/core';
import { ToasterService } from '../../../../../node_modules/angular2-toaster';
import { WorkshopRegistrationBackupApi } from '../../../shared/sdk';
import { of as observableOf } from 'rxjs';
import { ConfirmModalService } from './confirmmodal';

import { PreviewMailModalService } from '../preview-mail-modal/preview-mail-modal.service';
import { MailPreview } from '../preview-mail-modal/mail-preview.interface';

@Component({
  selector: 'snr-workshop-registration-backup-check-for-interest-button',
  templateUrl: './workshop-registration-backup-check-for-interest-button.component.html',
  styleUrls: ['./buttons.scss']
})
export class WorkshopRegistrationBackupCheckForInterestButtonComponent {
  @Input()
  workshopRegistrationBackup: any;
  @Output()
  sent: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private confirmModalService: ConfirmModalService,
    //tslint:disable-next-line
    private toasterService: ToasterService,
    //tslint:disable-next-line
    private workshopRegistrationBackupService: WorkshopRegistrationBackupApi,
    //tslint:disable-next-line
    private previewMailModalService: PreviewMailModalService
  ) {}

  onSendCheckForInterestMail(): void {
    const { header, content, options } = getHeaderContentAndOptions.call(this);
    this.confirmModalService.confirm(header, content, options, choices => {
      return doSendMail.call(this, choices.numberOfDays);
    });

    function getHeaderContentAndOptions() {
      return {
        header: 'Informeren naar interesse?',
        content: `
          <p>Weet je zeker dat je deze persoon een mail wilt sturen om te vragen of er nog interesse is om met de workshop/cursus mee te doen?</p>
          <p>Wat zal er gebeuren?</p>
          <ul>
            <li>Je geeft eerst aan over hoeveel dagen deze persoon dient te antwoorden</li>
            <li>De persoon krijgt een mail toegestuurd met daarin de vraag of ze binnen de X dagen kan laten weten of er nog interesse is, zodat ze een mail met de link kan toegestuurd krijgen.</li>
          </ul>`,
        options: [
          {
            name: 'numberOfDays',
            label: 'Hoeveel dagen heeft de persoon de tijd om te antwoorden?',
            inputType: 'number',
            value: 3
          },
          {
            name: 'checkEmails',
            label: 'Bekijk de mail die verstuurd zal worden',
            inputType: 'button',
            click: choices => {
              this.workshopRegistrationBackupService
                .getMailForCheckForInterest(this.workshopRegistrationBackup.id, choices.numberOfDays)
                .subscribe(mail => {
                  const mailPreviews: MailPreview[] = [
                    {
                      to: mail.to,
                      html: mail.body
                    }
                  ];
                  this.previewMailModalService.showMailPreviews(mailPreviews);
                });
            }
          }
        ]
      };
    }

    function doSendMail(numberOfDays) {
      return this.workshopRegistrationBackupService
        .sendCheckForInterest(this.workshopRegistrationBackup.id, numberOfDays)
        .flatMap(() => {
          this.sent.emit();
          this.toasterService.pop(
            'success',
            'Mail is verstuurd',
            'De mail om te polsen naar de interesse is onderweg naar de persoon...'
          );
          return observableOf(null);
        });
    }
  }
}
