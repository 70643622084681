import { Input, Component, Output, EventEmitter } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { ELearningClassEnrollmentApi } from '../../../shared/sdk';
import { of as observableOf } from 'rxjs';
import { ConfirmModalService } from './confirmmodal';

import { PreviewMailModalService } from '../preview-mail-modal/preview-mail-modal.service';
import { MailPreview } from '../preview-mail-modal/mail-preview.interface';

@Component({
  selector: 'snr-elearning-class-enrollment-mark-as-assembled-button',
  templateUrl: './elearning-class-enrollment-mark-as-assembled-button.component.html'
  // styleUrls: ['./buttons.scss']
})
export class ELearningClassEnrollmentMarkAsAssembledButtonComponent {
  @Input()
  eLearningClassEnrollment: any;
  @Output()
  assembled: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private confirmModalService: ConfirmModalService,
    //tslint:disable-next-line
    private toasterService: ToasterService,
    //tslint:disable-next-line
    private eLearningClassEnrollmentService: ELearningClassEnrollmentApi,
    //tslint:disable-next-line
    private previewMailModalService: PreviewMailModalService
  ) {}

  onMarkAsAssembled(): void {
    const { header, content, options } = getHeaderContentAndOptions.call(this);
    this.confirmModalService.confirm(header, content, options, chosenOptions => {
      return markAsAssembled.call(this, chosenOptions);
    });

    function getHeaderContentAndOptions() {
      const isPickUp = this.eLearningClassEnrollment.tuition.shippingData === null;
      return {
        header: 'Is de bestelling klaargemaakt?',
        content: `
          <p>Wat zal er gebeuren?</p>
          <ul>
            <li>
            ${
              isPickUp
                ? 'Bij de bestelling zal nu staan dat het klaar is om opgehaald te worden'
                : 'Bij de bestelling zal nu staan dat het klaar is om verstuurd te worden'
            }
            </li>
          </ul>`,
        options: [
          {
            name: 'doSendEmails',
            label:
            isPickUp
                ? 'Wil je de klant op de hoogte brengen dat de bestelling klaargemaakt is? Waarschijnlijk moet je hierna nog contact met de klant opnemen om te bepalen wanneer het pakje opgehaald kan worden.'
                : 'Wil je de klant op de hoogte brengen dat de bestelling klaargemaakt is? Dan weet die dat het pakje er binnenkort aankomt.',
            inputType: 'boolean',
            value: true
          },
          {
            name: 'checkEmails',
            label: 'Bekijk de mail die verstuurd zal worden',
            inputType: 'button',
            click: () => {
              this.eLearningClassEnrollmentService.getMailForMarkAsAssembled(this.eLearningClassEnrollment.id).subscribe(({ mail, to }) => {
                const mailPreview: MailPreview = {
                  to: to,
                  html: mail
                };
                this.previewMailModalService.showMailPreview(mailPreview);
              });
            }
          }
        ]
      };
    }

    function markAsAssembled(chosenOptions) {
      return this.eLearningClassEnrollmentService.markAsAssembled(this.eLearningClassEnrollment.id, chosenOptions.doSendEmails).flatMap(success => {
        this.assembled.emit();

        this.toasterService.pop(
          'success',
          'Bestelling is aangepast',
          'De bestelling staat nu klaar om verstuurd te worden of om opgehaald te worden'
        );
        return observableOf(null);
      });
    }
  }
}
