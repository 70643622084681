import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ELearningClassEnrollment } from 'app/shared/sdk';

@Component({
  selector: 'snr-elearning-class-enrollment-actions-modal',
  template: `
    <div class="modal-header" [ngClass]="{ 'is-busy': isBusy }">
      <span>
        <h3>Bestelling acties</h3>
        <small>
          <b>Klant</b>:
            {{ eLearningClassEnrollment.registrant.firstName }}
            {{ eLearningClassEnrollment.registrant.lastName }}
            (
              <a href="mailto:{{ eLearningClassEnrollment.registrant.emailAddress }}">
                {{ eLearningClassEnrollment.registrant.emailAddress }}
              </a>
            )
        </small>
        <br />
        <small>
          <b>Bestelling</b>: #{{ eLearningClassEnrollment.id }} ({{ eLearningClassEnrollment.access.accessibleFrom | date: 'short':null:'nl' }})
        </small>
        <br />
        <small>
          <b>Bedrag</b>: {{ Math.round(eLearningClassEnrollment.totalPrice * 100) / 100 }} &euro;
        </small>
      </span>
      <button class="close" aria-label="Close" (click)="onCloseButtonClicked()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="max-height: calc(100vh - 250px); overflow-y: auto;">
      <snr-elearning-class-enrollment-actions [eLearningClassEnrollment]="eLearningClassEnrollment" (change)="onELearningClassEnrollmentChanged($event)" (busy)="onBusy($event)"> </snr-elearning-class-enrollment-actions>
    </div>
    <div class="modal-footer">
      <button class="btn btn-md btn-primary" (click)="onCloseButtonClicked()">
        Sluiten
      </button>
    </div>
  `,
  styles: [
    `
      .modal-header.is-busy::after {
        animation: modalIsBusy 1s linear infinite;
        content: '.';
        font-size: 18em;
        padding-left: 35%;
        z-index: 1;
        position: absolute;
        top: -1px;
        left: -1px;
        bottom: -1px;
        right: -1px;
        background-color: rgba(0, 0, 0, 0.5);
        border: 1px solid #666;
        transition: 200ms content linear;
      }
    `
  ]
})
export class ELearningClassEnrollmentActionsModalComponent {
  eLearningClassEnrollment: ELearningClassEnrollment;
  change: Function;
  close: Function;

  isBusy: boolean = false;
  Math: any = Math;

  constructor(private activeModal: NgbActiveModal) {

  }

  onCloseButtonClicked(): void {
    this.activeModal.close();
    if (this.close) {
      this.close();
    }
  }

  onELearningClassEnrollmentChanged(event): void {
    if (this.change) {
      this.change(event);
    }
  }

  onBusy(event): void {
    this.isBusy = event;
  }
}
