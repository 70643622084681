import { Injectable } from '@angular/core';
import { ConfirmModalService } from 'app/pages/components/buttons/confirmmodal';
import { ToasterService } from 'angular2-toaster';
import { WorkshopRegistrationApi } from 'app/shared/sdk';

import { of as observableOf } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import * as moment from 'moment';
import { MailPreview } from 'app/pages/components/preview-mail-modal/mail-preview.interface';
import { PreviewMailModalService } from 'app/pages/components/preview-mail-modal/preview-mail-modal.service';

export interface WorkshopRegistrationPayment {
  amountPaid: number;
  amountDue: number;
}

@Injectable()
export class WorkshopRegistrationPaymentModalService {
  constructor(
    private confirmModalService: ConfirmModalService,
    private toasterService: ToasterService,
    private workshopRegistrationService: WorkshopRegistrationApi,
    private previewMailModalService: PreviewMailModalService
  ) {}

  async markPayment(workshopRegistration, workshop, workshopSubject): Promise<WorkshopRegistrationPayment> {
    return new Promise<WorkshopRegistrationPayment>((resolve, reject) => {
      const { header, content, options } = this.getHeaderContentAndOptions(
        workshopRegistration,
        workshop,
        workshopSubject
      );
      this.confirmModalService.confirm(header, content, options, chosenOptions => {
        //This gets called when the user confirms. It needs to return an observable, because the modal needs
        //  to be made aware when everything is handled, so it can close itself.
        let actuallyMarkPayment$ = this.actuallyMarkPayment(workshopRegistration.id, chosenOptions).pipe(
          tap((payment: WorkshopRegistrationPayment) => {
            resolve(payment);

            if (payment) {
              this.toasterService.pop(
                'success',
                'Betaling is geregistreerd',
                `Er is geregistreerd dat de cursist ${payment.amountPaid} euro heeft betaald`
              );
            }
          }),
          catchError((err) => {
            this.toasterService.pop(
              'error',
              'Het is niet gelukt om de betaling te registreren',
            );

            reject();
            return observableOf(err);
          })
        );
        return actuallyMarkPayment$; //This callback function needs to return the observable so the modal will know when everything is handled, and it may close the modal.
      });
    });
  }

  private actuallyMarkPayment(workshopRegistrationId, chosenOptions) {
    if (!chosenOptions.amount || chosenOptions.amount < 0) {
      return observableOf(null);
    }
    return this.workshopRegistrationService
      .markPayment(workshopRegistrationId, chosenOptions.amount, chosenOptions.doSendEmails)
      .flatMap(({ success, amountDue }) => {
        const payment: WorkshopRegistrationPayment = {
          amountPaid: chosenOptions.amount,
          amountDue: amountDue
        };
        return observableOf(payment);
      });
  }

  private getHeaderContentAndOptions(
    workshopRegistration,
    workshop,
    workshopSubject
  ): { header: string; content: string; options: any[] } {
    const { firstName, lastName, bankPaymentCode, amountDue } = workshopRegistration;
    const {
      isReferredToAsCourse,
      nl: { name: workshopName }
    } = workshopSubject;
    const denomination = isReferredToAsCourse ? 'cursus' : 'workshop';
    const isMultipleDays = workshop.dates.length > 1;
    const firstDay = moment(workshop.dates[0]);
    firstDay.locale('nl');
    const firstDayString = firstDay.format('LL');

    return {
      header: `De betaling van een ${denomination} registreren?`,
      content: `
        <p>Doe dit enkel als cursist <b>${firstName} ${lastName}</b> een deel of alles betaald heeft voor de <b>${workshopName}</b> die ${
        isMultipleDays ? 'start' : 'doorgaat'
      } op <b>${firstDayString}</b>.</p>
        <p>De code dewelke de cursist gebruikt zou moeten hebben voor de betaling is <b>${bankPaymentCode}</b></p>
        <p>Wat zal er gebeuren?</p>
        <ul>
          <li>Geef hieronder in hoeveel de cursist betaald heeft.</li>
          <li>Dit bedrag zal afgetrokken worden van het nog openstaande bedrag van de cursist (${amountDue} euro)</li>
        </ul>`,
      options: [
        {
          name: 'amount',
          label: `Hoeveel euro heeft de deelnemer betaald (het openstaand bedrag is ${amountDue} euro)?`,
          inputType: 'number',
          value: amountDue
        },
        {
          name: 'doSendEmails',
          label:
            'Wil je de deelnemer op de hoogte brengen dat we een betaling hebben ontvangen door een mail te sturen?',
          inputType: 'boolean',
          value: true
        },
        {
          name: 'checkEmails',
          label: 'Bekijk de mail die verstuurd zal worden',
          inputType: 'button',
          click: choices => {
            this.workshopRegistrationService
              .getMailForMarkPayment(workshopRegistration.id, choices.amount)
              .subscribe(({ body, to }) => {
                const mailPreview: MailPreview = {
                  html: body,
                  to: to
                };
                this.previewMailModalService.showMailPreview(mailPreview);
              });
          }
        }
      ]
    };
  }
}
