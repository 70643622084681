/* tslint:disable */

declare var Object: any;
export interface BookInterface {
  "title": string;
  "coverUrl": string;
  "slug": string;
  "type": string;
  "nl"?: any;
  "en"?: any;
  "author": string;
  "illustrator": string;
  "isbn": string;
  "publisher": string;
  "publishDate": string;
  "age": any;
  "translations"?: any;
  "id"?: any;
}

export class Book implements BookInterface {
  "title": string;
  "coverUrl": string;
  "slug": string;
  "type": string;
  "nl": any;
  "en": any;
  "author": string;
  "illustrator": string;
  "isbn": string;
  "publisher": string;
  "publishDate": string;
  "age": any;
  "translations": any;
  "id": any;
  constructor(data?: BookInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Book`.
   */
  public static getModelName() {
    return "Book";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Book for dynamic purposes.
  **/
  public static factory(data: BookInterface): Book{
    return new Book(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Book',
      plural: 'Books',
      path: 'Books',
      idName: 'id',
      properties: {
        "title": {
          name: 'title',
          type: 'string'
        },
        "coverUrl": {
          name: 'coverUrl',
          type: 'string'
        },
        "slug": {
          name: 'slug',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "nl": {
          name: 'nl',
          type: 'any'
        },
        "en": {
          name: 'en',
          type: 'any'
        },
        "author": {
          name: 'author',
          type: 'string'
        },
        "illustrator": {
          name: 'illustrator',
          type: 'string'
        },
        "isbn": {
          name: 'isbn',
          type: 'string'
        },
        "publisher": {
          name: 'publisher',
          type: 'string'
        },
        "publishDate": {
          name: 'publishDate',
          type: 'string'
        },
        "age": {
          name: 'age',
          type: 'any'
        },
        "translations": {
          name: 'translations',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
