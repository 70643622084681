import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule, NbPasswordAuthStrategy } from '../nebularauth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { DataModule } from './data/data.module';
import { AnalyticsService } from './utils/analytics.service';

const socialLinks = [
  {
    url: 'https://github.com/akveo/nebular',
    target: '_blank',
    icon: 'socicon-github'
  },
  {
    url: 'https://www.facebook.com/akveo/',
    target: '_blank',
    icon: 'socicon-facebook'
  },
  {
    url: 'https://twitter.com/akveo_inc',
    target: '_blank',
    icon: 'socicon-twitter'
  }
];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  ...DataModule.forRoot().providers,
  ...NbAuthModule.forRoot({
    strategies: [
      // NbDummyAuthStrategy.setup({
      //   name: "email",
      //   delay: 3000
      // }),
      NbPasswordAuthStrategy.setup({
        name: 'emailPasswordViaLoopback',
        baseEndpoint: 'https://api.snow-and-rose.com/api/Users/',
        //login doesn't need to be set, as the default settings are appropriate
        logout: {
          //Default setting (also 'endpoint') are ok, except the method, which was set to 'delete'
          method: 'post',
          redirect: {
            success: '/auth/login',
            failure: '/auth/login'
          }
          //I did however have to modify some code in password-strategy.ts's logout function, as Loopback needs the token to be
          //  added to the post url itself as access_token=...
          // ?access_token
        },
        token: {
          //The defaults, like class and getter don't need to be set; the data in here extends the default
          key: 'id' //After a correct login, the response gives back an object of which property 'id' is the token.
        },
        resetPass: {
          endpoint: 'reset-password',
          method: 'post'
          //   resetPasswordTokenKey: 'reset_password_token',
        }
        // register?: boolean | NbPasswordStrategyModule = {
        //   alwaysFail: false,
        //   rememberMe: true,
        //   endpoint: 'register',
        //   method: 'post',
        //   failWhenNoToken: true,
        //   redirect: {
        //     success: '/',
        //     failure: null,
        //   },
        //   defaultErrors: ['Something went wrong, please try again.'],
        //   defaultMessages: ['You have been successfully registered.'],
        // };
        // requestPass?: boolean | NbPasswordStrategyModule = {
        //   endpoint: 'request-pass',
        //   method: 'post',
        //   redirect: {
        //     success: '/',
        //     failure: null,
        //   },
        //   defaultErrors: ['Something went wrong, please try again.'],
        //   defaultMessages: ['Reset password instructions have been sent to your email.'],
        // };
        // refreshToken?: boolean | NbPasswordStrategyModule = {
        //   endpoint: 'refresh-token',
        //   method: 'post',
        //   failWhenNoToken: true,
        //   redirect: {
        //     success: null,
        //     failure: null,
        //   },
        //   defaultErrors: ['Something went wrong, please try again.'],
        //   defaultMessages: ['Your token has been successfully refreshed.'],
        // };
        // errors?: NbPasswordStrategyMessage = {
        //   key: 'data.errors',
        //   getter: (module: string, res: HttpErrorResponse, options: NbPasswordAuthStrategyOptions) => getDeepFromObject(
        //     res.error,
        //     options.errors.key,
        //     options[module].defaultErrors,
        //   ),
        // };
        // messages?: NbPasswordStrategyMessage = {
        //   key: 'data.messages',
        //   getter: (module: string, res: HttpResponse<Object>, options: NbPasswordAuthStrategyOptions) => getDeepFromObject(
        //     res.body,
        //     options.messages.key,
        //     options[module].defaultMessages,
        //   ),
        // };
        // validation?: {
        //   password?: {
        //     required?: boolean;
        //     minLength?: number | null;
        //     maxLength?: number | null;
        //     regexp?: string | null;
        //   };
        //   email?: {
        //     required?: boolean;
        //     regexp?: string | null;
        //   };
        //   fullName?: {
        //     required?: boolean;
        //     minLength?: number | null;
        //     maxLength?: number | null;
        //     regexp?: string | null;
        //   };
        // };
      })
    ],
    forms: {
      login: {
        socialLinks: socialLinks
      },
      register: {
        socialLinks: socialLinks
      }
    }
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*'
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*'
      }
    }
  }).providers,

  {
    provide: NbRoleProvider,
    useClass: NbSimpleRoleProvider
  },
  AnalyticsService
];

@NgModule({
  imports: [CommonModule],
  exports: [NbAuthModule],
  declarations: []
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [...NB_CORE_PROVIDERS]
    };
  }
}
