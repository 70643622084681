import {
  Input,
  Component,
  //Inject,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
//import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { LoopBackConfig, LoopBackAuth } from 'app/shared/sdk';

@Component({
  selector: 'snr-images-panel',
  templateUrl: './images-panel.component.html',
  styleUrls: ['./images-panel.component.scss']
})
export class ImagesPanelComponent implements OnChanges {
  @Input()
  imageUrls: string[];
  @Output()
  imageUrlsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  //duplicatedImageUrls is the one that is output in the UI. And the ones that aren't in imageUrls have been marked as deleted.
  duplicatedImageUrls: string[];

  fileDragInProgress: boolean = false;

  constructor() {}
  //@Inject(LoopBackAuth) protected auth: LoopBackAuth,
  // private http: HttpClient,
  // private toasterService: ToasterService

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.imageUrls) {
      if (!changes.imageUrls.currentValue) {
        this.imageUrls = [];
        this.duplicatedImageUrls = [];
      } else {
        this.duplicatedImageUrls = JSON.parse(JSON.stringify(changes.imageUrls.currentValue || []));
      }
    }
  }

  onDragOverFile(event): void {
    this.fileDragInProgress = true;
    event.stopPropagation();
    event.preventDefault();
  }
  onDragLeaveFile($event): void {
    this.fileDragInProgress = false;
    event.stopPropagation();
    event.preventDefault();
  }

  onDropFile(event: DragEvent) {
    this.fileDragInProgress = false;
    event.preventDefault();
    this._addImageUrl(event.dataTransfer.files);
  }

  onFileChanged(event) {
    this._addImageUrl(event.target.files);
  }

  onMarkImageUrlAsDeleted(imageUrl) {
    const index = this.imageUrls.indexOf(imageUrl);
    if (index !== -1) {
      this.imageUrls.splice(index, 1);
      this.imageUrlsChange.emit(this.imageUrls);

      if (imageUrl.indexOf('data') === 0) {
        //A dataUri; which means it's a newly added image. We will really be removing it...
        const indexInDuplicated = this.duplicatedImageUrls.indexOf(imageUrl);
        this.duplicatedImageUrls.splice(indexInDuplicated, 1);
      }
    }
  }

  onUnmarkImageUrlAsDeleted(imageUrl) {
    const index = this.duplicatedImageUrls.indexOf(imageUrl);
    if (index !== -1) {
      this.imageUrls.splice(index, 0, imageUrl);
      this.imageUrlsChange.emit(this.imageUrls);
    }
  }

  async _addImageUrl(files: FileList) {
    if (files.length === 0) {
      //console.log('No file selected!');
      return;
    }

    //files is not an array
    for (let i = 0, len = files.length; i < len; i++) {
      const file = files[i];
      const extension = file.name.split('.').slice(-1)[0];
      if (extension !== 'jpg' && extension !== 'jpeg' && extension !== 'png' && extension !== 'gif') {
        continue;
      }
      const dataUri: string = await new Promise<string>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.addEventListener(
          'load',
          () => {
            resolve(<string>fileReader.result);
          },
          false
        );
        fileReader.readAsDataURL(file);
      });
      if (this.duplicatedImageUrls.indexOf(dataUri) === -1) {
        this.imageUrls.push(dataUri);
        this.duplicatedImageUrls.push(dataUri);
      }
    }
    this.imageUrlsChange.emit(this.imageUrls);
  }
}
