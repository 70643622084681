// import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
// export class TokenGuardService {

//   constructor() { }
// }

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NbTokenService } from '@nebularauth/services/token/token.service';

@Injectable()
export class TokenGuardService implements CanActivate {
  constructor(protected tokenService: NbTokenService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.tokenService.get().pipe(
      map(
        (res: any /*NbAuthToken*/) => {
          //NbAuthToken apparently has no .token property - so changing it to 'any' so the compiler would shut up
          if (!res.token) {
            this.router.navigate(['/auth/login']);
            return false;
          }
          return true;
        },
        error => {
          this.router.navigate(['/auth/login']);
        }
      )
    );
  }
}
