/* tslint:disable */

declare var Object: any;
export interface MoodboardInterface {
  "name": string;
  "relatedWorkshopSubjectId": any;
  "imageUrls"?: any;
  "belongsToSupplementaryLessons": boolean;
  "id"?: any;
}

export class Moodboard implements MoodboardInterface {
  "name": string;
  "relatedWorkshopSubjectId": any;
  "imageUrls": any;
  "belongsToSupplementaryLessons": boolean;
  "id": any;
  constructor(data?: MoodboardInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Moodboard`.
   */
  public static getModelName() {
    return "Moodboard";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Moodboard for dynamic purposes.
  **/
  public static factory(data: MoodboardInterface): Moodboard{
    return new Moodboard(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Moodboard',
      plural: 'Moodboards',
      path: 'Moodboards',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "relatedWorkshopSubjectId": {
          name: 'relatedWorkshopSubjectId',
          type: 'any'
        },
        "imageUrls": {
          name: 'imageUrls',
          type: 'any'
        },
        "belongsToSupplementaryLessons": {
          name: 'belongsToSupplementaryLessons',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
