import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LogApi, WorkshopRegistrationApi } from '../../../shared/sdk';
import { ToasterService } from 'angular2-toaster';
// import 'rxjs/add/operator/mergeMap'; //flatMap

@Component({
  selector: 'snr-workshopregistration-actions',
  templateUrl: './workshopregistration-actions.component.html',
  styleUrls: ['./workshopregistration-actions.component.scss']
})
export class WorkshopRegistrationActionsComponent implements OnInit {
  @Input()
  workshopSubject: any;
  @Input()
  workshop: any;
  @Input()
  workshopRegistration: any;
  @Output()
  change: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  busy: EventEmitter<boolean> = new EventEmitter<boolean>();

  logsGroupedByDay: any[];

  constructor(
    private loggerService: LogApi,
    //tslint:disable-next-line
    private workshopRegistrationService: WorkshopRegistrationApi,
    //tslint:disable-next-line
    private toasterService: ToasterService
  ) {}

  ngOnInit() {}

  onPaymentInformationSent(sentPaymentInformation): void {
    //We just sent the participant a mail with the overview of her bill. Because of this, we add an extra item
    //  to the paymentLog of the workshopRegistration, because such an item has been added to the workshopRegistration
    //  in the backend.
    if (!this.workshopRegistration.paymentLog) {
      this.workshopRegistration.paymentLog = [];
    }
    this.workshopRegistration.paymentLog.push({
      timestamp: new Date(), //Doesn't matter this one is not exactly as the one in Mongo
      status: 'paymentRequested',
      amount: sentPaymentInformation.total
    });
    const whatHasChanged = {
      workshopRegistration: {
        id: this.workshopRegistration.id,
        relatedWorkshopId: this.workshopRegistration.relatedWorkshopId,
        paymentLog: this.workshopRegistration.paymentLog
        //no need to update amountDue, as it hasn't changed because of this action
      }
    };
    this.change.emit(whatHasChanged);
  }

  onPaid({ amountPaid, amountDue }): void {
    //We just marked the participant paid something. Most often this will be the entire bill; but it's always possible the participant
    //  has struck a deal to pay the bill in parts, or entered a wrong amount. Either way, we need to add an extra item to
    //  to the paymentLog, because that's what also happened on the server.
    if (!this.workshopRegistration.paymentLog) {
      this.workshopRegistration.paymentLog = [];
    }
    this.workshopRegistration.paymentLog.push({
      timestamp: new Date(), //Doesn't matter this one is not exactly as the one in Mongo
      status: 'paymentReceived',
      amount: amountPaid
    });
    const whatHasChanged = {
      workshopRegistration: {
        id: this.workshopRegistration.id,
        relatedWorkshopId: this.workshopRegistration.relatedWorkshopId,
        paymentLog: this.workshopRegistration.paymentLog,
        amountDue: amountDue
      }
    };
    this.change.emit(whatHasChanged);
  }

  onRefundDetermined(refundAmount): void {
    //In case a participant revoked her registration or we cancelled it, we need to determine how much we will refund.
    //Note that when a workshopRegistration changes, we do not manually need to determine whether we need to refund something or not. The logic
    //  when updating a workshopRegistration will determine the new amountDue. If it turns out amountDue is < 0, we will need to refund. Otherwise,
    //  the participant will just have to pay less.
    if (!this.workshopRegistration.paymentLog) {
      this.workshopRegistration.paymentLog = [];
    }
    this.workshopRegistration.paymentLog.push({
      timestamp: new Date(), //Doesn't matter this one is not exactly as the one in Mongo
      status: 'refundDetermined',
      amount: refundAmount
    });
    const whatHasChanged = {
      workshopRegistration: {
        id: this.workshopRegistration.id,
        relatedWorkshopId: this.workshopRegistration.relatedWorkshopId,
        paymentLog: this.workshopRegistration.paymentLog,
        amountDue: -refundAmount
      }
    };
    this.change.emit(whatHasChanged);
  }

  onRefunded(amountRefunded): void {
    //There are 3 cases when a refund is in order...
    //  1. The participant paid too much, and the amountDue has become negative. We need to refund.
    //  2. The participant cancelled/revoked, and we've determined the amount to refund. And now we're refunding it.
    //  3. The participant asked us to change the workshopRegistration. If the participant had already paid (part of fully), then it's possible
    //    the new amountDue will become less than 0, and then we should refund.
    if (!this.workshopRegistration.paymentLog) {
      this.workshopRegistration.paymentLog = [];
    }
    this.workshopRegistration.paymentLog.push({
      timestamp: new Date(), //Doesn't matter this one is not exactly as the one in Mongo
      status: 'refundSent',
      amount: amountRefunded
    });
    const whatHasChanged = {
      workshopRegistration: {
        id: this.workshopRegistration.id,
        relatedWorkshopId: this.workshopRegistration.relatedWorkshopId,
        paymentLog: this.workshopRegistration.paymentLog,
        amountDue: this.workshopRegistration.amountDue + amountRefunded //should be 0, as .amountDue was negative, and amountRefunded was positive.
      }
    };
    this.change.emit(whatHasChanged);
  }

  onInvoiceSent(): void {
    //We just marked that we've sent the invoice to the participant. To register this, we need to add an extra item to
    //  to the paymentLog, because that's what also happened on the server.
    if (!this.workshopRegistration.paymentLog) {
      this.workshopRegistration.paymentLog = [];
    }
    this.workshopRegistration.paymentLog.push({
      timestamp: new Date(), //It doesn't matter that this one is not exactly the same as the one in Mongo
      status: 'invoiceSent'
    });
    const whatHasChanged = {
      workshopRegistration: {
        id: this.workshopRegistration.id,
        paymentLog: this.workshopRegistration.paymentLog
      }
    };
    this.change.emit(whatHasChanged);
  }

  onInfoSent(): void {
    this.workshopRegistration._receivedInfoMail = true;
    const whatHasChanged = {
      workshopRegistration: {
        id: this.workshopRegistration.id,
        relatedWorkshopId: this.workshopRegistration.relatedWorkshopId,
        _receivedInfoMail: true
      }
    };
    this.change.emit(whatHasChanged);
  }
  onLastReminderSent(): void {
    this.workshopRegistration._receivedLastReminderMail = true;
    const whatHasChanged = {
      workshopRegistration: {
        id: this.workshopRegistration.id,
        relatedWorkshopId: this.workshopRegistration.relatedWorkshopId,
        _receivedLastReminderMail: true
      }
    };
    this.change.emit(whatHasChanged);
  }

  onPersonRegistered(): void {
    this.logsGroupedByDay = null;
    const whatHasChanged = {
      workshopRegistration: {
        id: this.workshopRegistration.id,
        relatedWorkshopId: this.workshopRegistration.relatedWorkshopId,
        status: this.workshopRegistration.status //'registered'
      }
    };
    this.change.emit(whatHasChanged);
  }

  onPersonParticipating(): void {
    this.logsGroupedByDay = null;
    const whatHasChanged = {
      workshopRegistration: {
        id: this.workshopRegistration.id,
        relatedWorkshopId: this.workshopRegistration.relatedWorkshopId,
        status: this.workshopRegistration.status //'participating'
      }
    };
    this.change.emit(whatHasChanged);
  }

  onWorkshopRegistrationCancelled(): void {
    //When we cancelled a workshopRegistration, the participant will get a full refund.
    //The workshopRegistration was altered by the cancelWorkshopRegistrationButton; the only thing left for us to do is keep a possible
    //  invoice's status up to date, and that the same functionality whether we're cancelling or the participant is revoking.
    this._onWorkshopRegistrationCancelledOrRevoked();
  }

  onWorkshopRegistrationRevoked(): void {
    //When a participant wants to revoke her workshopRegistration, we might need to refund here. The exact amount is determined in the backend
    //  and depends on whether we want to apply the cancellation terms etc...
    this._onWorkshopRegistrationCancelledOrRevoked();
  }

  private _onWorkshopRegistrationCancelledOrRevoked(): void {
    this.logsGroupedByDay = null;
    const whatHasChanged = {
      workshopRegistration: {
        id: this.workshopRegistration.id,
        relatedWorkshopId: this.workshopRegistration.relatedWorkshopId,
        status: this.workshopRegistration.status, //either 'revoked' or 'cancelled'
        amountDue: this.workshopRegistration.amountDue //These got updated as well. Now we're just passing it along.
      }
    };
    this.change.emit(whatHasChanged);
  }

  onTabChanged(event: any) {
    if (event.tabTitle === 'Log' && !this.logsGroupedByDay) {
      this.loggerService
        .find({
          where: {
            relatedWorkshopRegistrationId: this.workshopRegistration.id
          }
        })
        .subscribe(logs => {
          this.logsGroupedByDay = <any[]>logs.reduce((memo: any[], log: any) => {
            const lastLoggedDay = memo.length ? memo[memo.length - 1].day.getTime() : null;
            let day = new Date(log.timestamp);
            day.setHours(0, 0, 0, 0);
            if (lastLoggedDay !== day.getTime()) {
              memo.push({
                day: day,
                logs: []
              });
            }
            memo[memo.length - 1].logs.unshift(log);
            return memo;
          }, []);
          this.logsGroupedByDay.reverse();
        });
    }
  }
}
